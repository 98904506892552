import React, {useEffect} from "react";
import "../styles/blocks/_giftcard.scss";
import logoAlexela from "../images/logo-white.svg";
import logoTakupoiss from "../images/logo-white-takupoiss.svg";
import {Link} from "react-router-dom";
import AlertMessage from '../components/common/AlertMessage';
import {FormattedMessage, useIntl} from "react-intl";
import {Typography} from "@rmwc/typography";
import {useDispatch, useSelector} from "react-redux";
import {getCardQR} from "../actions/cardActions";
import moment from "moment";
import LanguageSelector from "../components/LanguageSelector";
import Loader from "../components/common/Loader";
import {StartPath} from "../const/routes";
import classNames from 'classnames';

const GiftCard = ({giftCardType}) => {
    const cardQR = useSelector(state => state.cardQR);
    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        if (!cardQR.fetching && !cardQR.fetched) {
            const urlParams = new URLSearchParams(window.location.search);
            let encodedNumber = urlParams.get('code');
            if (!encodedNumber) {
                encodedNumber = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            }
            dispatch(getCardQR(decodeURIComponent(encodedNumber)));
        }
    }, [dispatch]);

    const logo = giftCardType === "Takupoiss" ? logoTakupoiss : logoAlexela;

    return (
        <div className={classNames("giftcard", giftCardType?.toLowerCase())}>
            <div className="giftcard__language">
                <LanguageSelector/>
            </div>
            <Link to={StartPath} className="link">
                <img className="giftcard__logo" alt="Alexela" src={logo}/>
            </Link>
            {cardQR.fetching &&
                <div className="mt-45">
                    <Loader isWhite={true}/>
                </div>
            }
            {!cardQR.fetching &&
                <>
                    {cardQR.data.base64Image ?
                        <>
                            <Typography
                                use="headline1"
                                tag="p"
                                className="giftcard__title"
                            >
                                {intl.formatNumber(
                                    cardQR.data.balance,
                                    {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    }
                                )}€
                            </Typography>
                            <Typography
                                use="headline1"
                                className="mb-25"
                            >
                                <FormattedMessage id="Cards.GiftCard"/>
                            </Typography>
                            <Typography
                                use="body1"
                                className="giftcard__highlight mb-30"
                            >
                                <b>
                                    <FormattedMessage id="Cards.ValidUntil"/>{" "}
                                    {moment(cardQR.data.expiryDate).format("DD.MM.YYYY")}
                                </b>
                            </Typography>
                            <img className="giftcard__logo" alt="GiftCard QR" src={cardQR.data.base64Image}/>
                            <div className="giftcard__terms">
                                <Typography
                                    use="body1"
                                    className="giftcard__limiter"
                                >
                                    <b>
                                        <FormattedMessage id="Cards.GiftCard.Terms"/>{" "}
                                    </b>
                                    <FormattedMessage id={giftCardType
                                        ? `Cards.GiftCard.${giftCardType}.Terms.Content1`
                                        : "Cards.GiftCard.Terms.Content1"}/>
                                </Typography>

                                <Typography
                                    use="body1"
                                    className=""
                                >
                                    <FormattedMessage id={giftCardType
                                        ? `Cards.GiftCard.${giftCardType}.Terms.Content2`
                                        : "Cards.GiftCard.Terms.Content2"}/>
                                </Typography>
                                <Typography
                                    use="body1"
                                    className="mb-15"
                                >
                                    <FormattedMessage id="Cards.GiftCard.Terms.Content3"/>
                                </Typography>
                                <Typography
                                    use="body1"
                                    className=""
                                >
                                    <FormattedMessage id="Cards.Contact.Phone"/>
                                </Typography>
                                <Typography
                                    use="body1"
                                    className=""
                                >
                                    <FormattedMessage id="Cards.Contact.Email"/>
                                </Typography>
                            </div>
                        </>
                        :
                        <AlertMessage
                            className="giftcard__notice"
                            title={<FormattedMessage id="Cards.GiftCard.NotFound"/>}
                        />
                    }
                </>
            }
        </div>
    );
}

export default GiftCard;

import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Grid, GridCell } from "@rmwc/grid";
import { Dialog, DialogTitle, DialogContent, DialogButton } from "@rmwc/dialog";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import { updateCards, removeCardHolder } from "../../actions/cardActions";
import Loader from "../common/Loader";
import TextFieldGroup from "../common/TextFieldGroup";
import BaseFormComponent from "../common/BaseFormComponent";
import SimpleReactValidator from "simple-react-validator";
import AlertMessage from "../common/AlertMessage";
import SelectField from "../common/SelectField";
import {StatusOk, StatusInProduction, StatusClosedByClient, StatusClosedStoplist} from "../../const/cardStatus";
import PaymentCards from "../../pages/PaymentCards";
import { Products, FuelTypes } from "../../const/FuelItems";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";
import { IconButton } from "@rmwc/icon-button";
import ConfirmDialog from "../common/ConfirmDialog";
import auth from "../../auth/authenticate";

import "../../styles/react-components/dialog.scss";
import "../../styles/components/_card-dialog.scss";

class CardDialog extends BaseFormComponent {
    static DailyLimit = "dailyLimit";
    static MonthlyLimit = "monthlyLimit";
    static SelectedStatus = "selectedStatus";
    static SelectedProduct = "selectedProduct";
    static SelectedFuel = "selectedFuel";
    static CardName = "cardName";
    static CardHolderIdCode = "cardHolderIdCode";
    static statusValues = [
        {
            label: "Cards.Status.Ok",
            value: StatusOk
        },
        {
            label: "Cards.Status.Closed",
            value: StatusClosedByClient
        },
        {
            label: "Cards.Status.ClosedStoplist",
            value: StatusClosedStoplist
        },
    ];

    constructor(props) {
        super(props);

        const firstItem = props.items[0] || {
            cardName: "",
            limitDaily: 0,
            limitMonthly: 0,
            cardHolderIdCode: ""
        };

        this.productOptions = Products.map((x, i) => (
            {
                label: this.props.intl.formatMessage({ id: x.label }),
                value: x.value,
                selected: i === 0,
            }
        ));

        this.fuelOptions = FuelTypes.map((x, i) => (
            {
                label: this.props.intl.formatMessage({ id: x.label }),
                value: x.value,
                selected: i === 0,
            }
        ));

        let cardName = firstItem.cardName;
        let dailyLimit = firstItem.limitDaily.toString();
        let monthlyLimit = firstItem.limitMonthly.toString();
        let contractLimit = firstItem.contractLimit || 0;
        let disableStatusChange = false;
        let selectedStatus = CardDialog.statusValues[0].value;
        let selectedProduct = firstItem.cardProductsXc || this.productOptions[0].value;
        let selectedFuel = firstItem.cardFuelTypeXc || this.fuelOptions[0].value;
        let cardHolderIdCode = firstItem.cardHolderIdCode;
        const cardHolderName = firstItem.cardHolderName;
        const cardNumber = firstItem.cardNumber;

        if (props.items.length === 1) {
            const cardStatus = firstItem.cardStatusXc;
            if (cardStatus === StatusClosedByClient) {
                selectedStatus = CardDialog.statusValues[1].value;
            }
            else if (cardStatus === StatusClosedStoplist) {
                selectedStatus = CardDialog.statusValues[2].value;
                disableStatusChange = true;
            }
            else if (cardStatus !== StatusOk && cardStatus !== StatusInProduction) {
                selectedStatus = CardDialog.statusValues[1].value;
                disableStatusChange = true;
            }
        }
        this.state = {
            showError: false,
            errorCode: '',
            isConfirmDialogOpen: false,
            dailyLimit: dailyLimit,
            monthlyLimit: monthlyLimit,
            selectedStatus: selectedStatus,
            statusChangeDisabled: disableStatusChange,
            cardName: cardName,
            contractLimit: contractLimit,
            selectedProduct: selectedProduct,
            selectedFuel: selectedFuel,
            cardHolderIdCode: cardHolderIdCode ?? '',
            initialCardHolderIdCode: cardHolderIdCode ?? '',
            cardHolderName: cardHolderName,
            cardNumber: cardNumber,
            isCompany: auth.isCompany() || false,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
        this.onRemoveCardHolder = this.onRemoveCardHolder.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale,
            validators: {
                ...IdCodeValidatorRule.rule(),
                dailyLimit: {
                    message: this.props.intl.formatMessage({ id: "Cards.Validator.LimitError" }),
                    rule: (val, params) => val <= parseInt(params[0])
                }
           }
        });
    }

    onRemoveCardHolder() {
        if (!this.state.isCompany) {
            return;
        }

        if (!this.state.initialCardHolderIdCode) {
            return;
        }

        this.setState({
            showError: false,
            errorCode: ''
        });

        const data = {
            cardNumber: this.state.cardNumber,
            cardHolderIdCode: this.state.initialCardHolderIdCode
        };

        this.props.removeCardHolder(data)
            .then(() => this.setState({ isConfirmDialogOpen: false }))
            .then(() => this.props.onSuccess())
            .then(this.props.onClose)
            .catch((e) => {
                this.setState({ 
                    showError: true, 
                    errorCode: 'RemoveCardHolderError',
                    isConfirmDialogOpen: false
            });
        });
    };

    sendRequest() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.setState({
            showError: false,
            errorCode: ''
        });

        const limitDaily = parseFloat(this.state.dailyLimit);
        const limitMonthly = parseFloat(this.state.monthlyLimit);

        const items = this.props.items.map((item) => {
            return {
                limitDaily: limitDaily,
                limitMonthly: limitMonthly,
                cardNumber: item.cardNumber,
                cardStatusXc: this.state.selectedStatus,
                cardName: this.state.cardName,
                cardProductsXc: this.state.selectedProduct,
                cardFuelTypeXc: this.state.selectedFuel,
                cardHolderIdCode: this.state.cardHolderIdCode
            }
        });

        this.props.updateCards(items)
            .then(() => this.props.onSuccess())
            .then(this.props.onClose)
            .catch((e) => {
                const errorCode = e.response?.data?.model?.errorCode ?? '';
                this.setState({ showError: true, errorCode});
            });
    }

    renderDialogTitle() {
        return (
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss" />
            </DialogTitle>
        );
    }

    renderTitle() {
        let titleId = "Cards.Title.ChangeCard";
        if (this.props.items.length > 1) {
            titleId = "Cards.Title.MassChange";
        }
        const itemNames = this.props.items.map(item => item.cardNumber).join(", ")
        return (
            <>
                <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                    <FormattedMessage id={titleId} />
                </Typography>
                <div className="table-list__header-style mt-24">
                   <FormattedMessage id="General.ToBeChanged" />{` ${itemNames}`}
                </div>
            </>
        );
    }

    render() {
        const options = CardDialog.statusValues.map(x => (
            {
                label: this.props.intl.formatMessage({ id: x.label }),
                value: x.value,
                selected: x.value === this.state.selectedStatus,
            }
        ));

        if (this.state.selectedStatus === StatusOk) {
            options.pop();
        }

        return (
            <Dialog
                className="card-dialog"
                open={this.props.isOpen}
                onClose={this.props.onClose}
            >
                {this.renderDialogTitle()}
                <DialogContent>
                    {this.props.updateUserFetching ? <Loader type={Loader.TYPE_CENTER}/> :
                    <Grid className="mdc-layout-grid--padded">
                        <GridCell span={12}>
                            {this.renderTitle()}
                            {this.state.showError &&
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_ALERT}
                                    title={this.props.intl.formatMessage({ id: `Cards.Change.${this.state.errorCode ?? 'Error'}` })}
                                />
                            }
                        </GridCell>
                        <GridCell span={12}>
                            <TextFieldGroup
                                field={CardDialog.CardName}
                                onChange={this.onChange}
                                validator={this.validator}
                                rules={`required|max:${PaymentCards.MaxAllowedCardNameLength}`}
                                value={this.state.cardName}
                                label="Cards.Label.CardName"
                            />
                        </GridCell>
                        <GridCell span={12}>
                            <TextFieldGroup
                                field={CardDialog.MonthlyLimit}
                                onChange={this.onChange}
                                validator={this.validator}
                                rules="required|numeric"
                                value={this.state.monthlyLimit}
                                type="number"
                                label="Cards.Label.MonthlyLimit"
                            />
                        </GridCell>
                        <GridCell span={12}>
                            <TextFieldGroup
                                field={CardDialog.DailyLimit}
                                onChange={this.onChange}
                                validator={this.validator}
                                rules={`required|numeric|dailyLimit:${this.state.monthlyLimit}`}
                                value={this.state.dailyLimit}
                                type="number"
                                label="Cards.Label.DailyLimit"
                            />
                        </GridCell>

                        <GridCell span={12}>
                            <SelectField
                                field={CardDialog.SelectedProduct}
                                label="Cards.Label.AllowedProducts"
                                disabled={this.state.statusChangeDisabled}
                                value={this.state.selectedProduct}
                                onChange={this.onSelectChange}
                                enhanced
                                menu
                                outlined
                                options={this.productOptions} />
                        </GridCell>
                        <GridCell span={12}>
                            <SelectField
                                field={CardDialog.SelectedFuel}
                                label="Cards.Label.AllowedFuels"
                                disabled={this.state.statusChangeDisabled}
                                value={this.state.selectedFuel}
                                onChange={this.onSelectChange}
                                enhanced
                                menu
                                outlined
                                options={this.fuelOptions} />
                        </GridCell>

                        <GridCell span={8}>
                            <SelectField
                                field={CardDialog.SelectedStatus}
                                label="Cards.Label.Status"
                                disabled={this.state.statusChangeDisabled}
                                value={this.state.selectedStatus}
                                onChange={this.onSelectChange}
                                enhanced
                                menu
                                outlined
                                options={options} />
                        </GridCell>
                        {this.state.isCompany &&
                            <GridCell span={12}>
                                {this.state.initialCardHolderIdCode ?
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex flex-dir-column mr-10">
                                            <label htmlFor={CardDialog.CardHolderIdCode}><FormattedMessage id="Cards.Label.CardHolder" /></label>
                                            <span>{`${this.state.cardHolderName} ${this.state.initialCardHolderIdCode}`}</span>
                                        </div>
                                        
                                        <IconButton
                                            onClick={() => this.setState({ isConfirmDialogOpen: true })}
                                            onIcon={{
                                                icon: "trash-can-regular",
                                                strategy: "className",
                                                basename: "icon",
                                                prefix: "icon-",
                                                className: "delete"
                                            }}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <TextFieldGroup
                                            field={CardDialog.CardHolderIdCode}
                                            onChange={this.onChange}
                                            validator={this.validator}
                                            rules="idCode:EE"
                                            value={this.state.cardHolderIdCode}
                                            label="Cards.Label.CardHolderIdCode" />
                                        <FormattedMessage id="Cards.AddCardHolderIdCodeInfo" />
                                    </div>
                                }
                            </GridCell>
                        }

                        <GridCell span={12}>
                            <Button type="submit"
                                onClick={this.sendRequest}
                                unelevated
                                className="mdc-button--primary mb-30">
                                <i className="icon-floppy" />
                                <FormattedMessage id="Cards.Change" />
                            </Button>
                        </GridCell>
                    </Grid>
                    }
                </DialogContent>
                
                {this.state.isCompany && 
                    <ConfirmDialog
                        isOpen={this.state.isConfirmDialogOpen}
                        text={this.props.intl.formatMessage({ id: "Cards.RemoveCardHolderDialog.Title" })}
                        description={this.props.intl.formatMessage({ id: "Cards.RemoveCardHolderDialog.Description" })}
                        onReject={() => this.setState({ isConfirmDialogOpen: false })}
                        onAccept={this.onRemoveCardHolder}
                    />
                }
                
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        updateUser: state.updateCards.data,
        updateUserFetched: state.updateCards.fetched,
        updateUserFetching: state.updateCards.fetching,
        updateUserError: state.updateCards.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ updateCards, removeCardHolder }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(CardDialog)));
import {OFFERS, UPSERT_OFFER} from "../../actions/offersActions";

export default function reducer(state={
    data: null,
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${UPSERT_OFFER}_PENDING`: {
            return {...state, fetching: true, fetched: false, error: null};
        }
        case `${UPSERT_OFFER}_REJECTED`: {
            return {...state, fetching: false, error: action.payload.response?.data?.errorMessage ?? null, fetched: false};
        }
        case `${UPSERT_OFFER}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}
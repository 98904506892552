import EeIdCodeValidator from './EeIdCodeValidator';

// Lt uses the same format as Estonia
class LtIdCodeValidator extends EeIdCodeValidator {
    /**
     *  Validates the Lithuanian personal ID.
     *
     *  @return boolean
     */
    validate(code) {
        code = code.replace('LT', '');
        if (code.length !== 11) {
            return false;
        }

        let control = this.getControlNumber(code);

        return control === parseInt(code.charAt(10), 10);
    }
}

export default LtIdCodeValidator;
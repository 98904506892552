import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Grid, GridCell } from "@rmwc/grid";
import { Dialog, DialogTitle, DialogContent, DialogButton } from "@rmwc/dialog";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import "../../styles/react-components/dialog.scss";
import { createAuthorizedUser, updateAuthorizedUsers } from "../../actions/accountActions";
import Loader from "../common/Loader";
import TextFieldGroup from "../common/TextFieldGroup";
import BaseFormComponent from "../common/BaseFormComponent";
import SimpleReactValidator from "simple-react-validator";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";
import RequiredIfValidatorRule from "../../validators/RequiredIfValidatorRule";
import DatePicker from "../common/DatePicker";
import moment from 'moment';
import AlertMessage from "../common/AlertMessage";
import Checkbox from '@material/react-checkbox';
import CheckboxField from "../common/CheckboxField";
import auth from "../../auth/authenticate";

class AuthorizationDialog extends BaseFormComponent {
    static CrmId = "crmId";
    static SSN = "ssn";
    static startDate = "startDate";
    static endDate = "endDate";

    static selectAllOption = {
        contractId: -1,
        contractName: "",
        contractNumber: ""
    };

    constructor(props) {
        super(props);

        let startDate = moment(new Date());
        let endDate;
        if (props.items.length === 1) {
            startDate =  moment(props.items[0].validFrom, "YYYY-MM-DD");
            const itemValidTo = props.items[0].validTo;
            endDate = itemValidTo ? moment(itemValidTo, "YYYY-MM-DD") : null;
        }

        const isEditMode = props.items.length > 0;
        const isSingleEdit = props.items.length === 1;

        this.state = {
            isAdmin: auth.isAdmin() || false,
            showError: false,
            ssn: "",
            showAgentModal: true,
            startDate: startDate,
            endDate: endDate,
            showSSN: !isEditMode,
            showStartDate: props.items.length <= 1,
            selectedContracts: isSingleEdit ? [...props.items[0].contracts] : [],
            canAddAuthorizations: isSingleEdit ? props.items[0].canAddAuthorizations : false
        }

        this.sendRequest = this.sendRequest.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onCanAddAuthorizationsChanged = this.onCanAddAuthorizationsChanged.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
        this.renderButtonTitle = this.renderButtonTitle.bind(this);
        this.selectAllContracts = this.selectAllContracts.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.validator = new SimpleReactValidator({
            validators: { ...RequiredIfValidatorRule.rule(), ...IdCodeValidatorRule.rule() },
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    onStartDateChange(value) {
        this.setState({
            startDate: moment(value)
        });
    }

    onEndDateChange(value) {
        this.setState({
            endDate: value ? moment(value) : null
        });
    }

    onCanAddAuthorizationsChanged(value) {
        this.setState({
            canAddAuthorizations: value
        });
    }

    sendRequest() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.setState({
            showError: false
        })

        if (this.props.items.length === 0) {
            this.props.createAuthorizedUser({
                ssn: this.state.ssn,
                validFrom: this.state.startDate.format("YYYY-MM-DD"),
                validTo: this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null,
                canAddAuthorizations: this.state.canAddAuthorizations,
                contracts: this.state.selectedContracts
            })
            .then(this.props.onSuccess)
            .then(this.props.onClose)
            .catch(() => {
                this.setState({
                    showError: true
                })
            })
        } else {
            const overwriteStart = this.props.items.length < 2;
            const items = this.props.items.map((item) => {
                return {
                    ssn: item.ssn,
                    validFrom: overwriteStart ? this.state.startDate.format("YYYY-MM-DD") : item.validFrom,
                    validTo: this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null,
                    canAddAuthorizations: this.state.canAddAuthorizations,
                    contracts: this.state.selectedContracts
                }
            })
            this.props.updateAuthorizedUsers(items)
                .then(this.props.onSuccess)
                .then(this.props.onClose)
                .catch(() => {
                    this.setState({
                        showError: true
                    })
                });
        }
    }

    renderDialogTitle() {
        return (
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss" />
            </DialogTitle>
        );
    }

    renderTitle() {
        let titleId = "AuthorizedUser.Title.Create";
        if (this.props.items.length === 1) {
            titleId = "AuthorizedUser.Title.Change";
        } else if (this.props.items.length > 1) {
            titleId = "AuthorizedUser.Title.MassChange";
        }
        const itemNames = this.props.items.map(item => item.ssn).join(", ")

        return (
            <>
                <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                    <FormattedMessage id={titleId} />
                </Typography>
                {this.props.items.length > 0 &&
                    <div className="table-list__header-style mt-24">
                        <FormattedMessage id="General.ToBeChanged" />{` ${itemNames}`}
                    </div>
                }
            </>
        );
    }

    renderButtonTitle() {
        let titleId = "AuthorizedUser.Create";
        if (this.props.items.length > 0) {
            titleId = "AuthorizedUser.Change";
        }

        return (
            <FormattedMessage id={titleId} />
        );
    }

    selectAllContracts() {
        this.setState({
            selectedContracts: this.state.selectedContracts.length === this.props.authorizedContracts.length
                ? []
                : [...this.props.authorizedContracts.map(c => c.contractId)]
        });
    }

    onSelectionChanged(id, index) {
        if (index === 0) {
            this.selectAllContracts();
            return;
        }

        const isAlreadySelected = this.state.selectedContracts.some(contractId => contractId === id);
        if (isAlreadySelected) {
            const newSelectedContracts = this.state.selectedContracts
                .filter(contractId => contractId !== id)

            this.setState({
                selectedContracts: [...newSelectedContracts]
            });
            return;
        }

        this.setState({
            selectedContracts: [...this.state.selectedContracts, id]
        });
    }

    render() {
        const endDate = this.state.endDate ? this.state.endDate.toDate() : null
        const contracts = this.props.authorizedContractsError === null && this.props.authorizedContracts.length
            ? [AuthorizationDialog.selectAllOption, ...this.props.authorizedContracts] : [];

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onClose}
                className="mdc-dialog--info"
            >
                {this.renderDialogTitle()}
                <DialogContent>
                    {this.props.createUserFetching || this.props.updateUserFetching || this.props.authorizedContractsFetching
                        ? <Loader type={Loader.TYPE_CENTER}/> :
                    <Grid className="mdc-layout-grid--padded">
                        <GridCell span={12} >
                            {this.renderTitle()}
                            {this.state.showError &&
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_ALERT}
                                    title={<FormattedMessage id="AuthorizedUser.Error"/>}
                                />
                            }
                        </GridCell>
                        {this.state.showSSN &&
                            <GridCell span={12} >
                                <TextFieldGroup
                                    field={AuthorizationDialog.SSN}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    rules={`requiredIf:${this.state.showSSN}|idCode:${this.state.ssn?.startsWith('LT') ? 'LT' : 'EE'}`}
                                    value={this.state.ssn}
                                    label="AuthorizedUser.SSN"
                                />
                            </GridCell>
                        }
                        {this.state.showStartDate &&
                            <GridCell span={12}>
                                <DatePicker
                                    label="AuthorizedUser.Start"
                                    field={AuthorizationDialog.startDate}
                                    value={this.state.startDate.toDate()}
                                    validator={this.validator}
                                    autoComplete="off"
                                    onChange={this.onStartDateChange}
                                    locale={this.props.intl.locale}
                                />
                            </GridCell>
                        }
                        <GridCell span={12}>
                            <DatePicker
                                label="AuthorizedUser.End"
                                field={AuthorizationDialog.endDate}
                                value={endDate}
                                autoComplete="off"
                                onChange={this.onEndDateChange}
                                locale={this.props.intl.locale}
                                isNullable
                            />
                        </GridCell>
                        {(this.state.isAdmin || this.props.canUserAddAuthorizations) &&
                            <GridCell span={12}>
                                <CheckboxField
                                    nativeControlId="cbCanAddAuthorizations"
                                    field="canAddAuthorizations"
                                    checked={this.state.canAddAuthorizations}
                                    indeterminate={false}
                                    onChange={e => this.onCanAddAuthorizationsChanged(e.target.checked)}
                                    disabled={false}
                                    label={
                                        <label htmlFor="cbCanAddAuthorizations">
                                            <FormattedMessage id="AuthorizedUser.Title.CanAddAuthorizations" />
                                        </label>
                                    }
                                />
                            </GridCell>
                        }
                        <GridCell span={12}>
                            <Typography use="headline5" className="mb-15">
                                <FormattedMessage id="AuthorizedUser.AuthorizedContracts" />
                            </Typography>
                            <div className="fields-group__list fields-group__list--text">
                                {contracts.map(({ contractId, contractName, contractNumber }, i) => {
                                    const key = `contract_${i}`;
                                    const displayName = i === 0
                                        ? <FormattedMessage id="AuthorizedUser.SelectAll" />
                                        : `${contractName} (${contractNumber})`;
                                    const isChecked = (i === 0 && this.state.selectedContracts.length === this.props.authorizedContracts.length) ||
                                        this.state.selectedContracts.indexOf(contractId) > -1;
                                    return <div className="fields-group__list-item" key={key}>
                                        <div className="mdc-form-field">
                                            <Checkbox
                                                nativeControlId={key}
                                                name={key}
                                                checked={!!isChecked}
                                                indeterminate={false}
                                                onChange={() => this.onSelectionChanged(contractId, i)}
                                                disabled={false}
                                            />
                                        </div>
                                        <label className="text" htmlFor={key}>
                                            <span className="primary-text">{displayName}</span>
                                        </label>
                                    </div>
                                })}
                            </div>
                        </GridCell>
                        <GridCell span={12} className="mb-60">
                            <Button type="submit"
                                onClick={this.sendRequest}
                                unelevated
                                className="mdc-button--primary">
                                <i className="icon-floppy" />
                                {this.renderButtonTitle()}
                            </Button>
                        </GridCell>
                    </Grid>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        createUser: state.postAuthorizedUser.data,
        createUserFetched: state.postAuthorizedUser.fetched,
        createUserFetching: state.postAuthorizedUser.fetching,
        createUserError: state.postAuthorizedUser.error,

        updateUser: state.putAuthorizedUser.data,
        updateUserFetched: state.putAuthorizedUser.fetched,
        updateUserFetching: state.putAuthorizedUser.fetching,
        updateUserError: state.putAuthorizedUser.error,

        authorizedContracts: state.authorizedContracts.data,
        authorizedContractsFetched: state.authorizedContracts.fetched,
        authorizedContractsFetching: state.authorizedContracts.fetching,
        authorizedContractsError: state.authorizedContracts.error,

        authorizedUsers: state.authorizedUser.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ createAuthorizedUser, updateAuthorizedUsers }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(AuthorizationDialog)));
import { combineReducers } from "redux";
import { intlReducer } from 'react-intl-redux';

import auth from "./auth/authReducer";
import addressCounties from "./address/countiesReducer";
import addressCities from "./address/citiesReducer";
import addressParishes from "./address/parishesReducer";
import addressStreets from "./address/streetsReducer";
import refreshJWT from "./auth/refreshJwtReducer";
import roles from "./account/rolesReducer";
import setRole from "./account/setRoleReducer";
import contact from "./contact/contactReducer";
import contacts from "./contact/contactsReducer";
import account from "./account/accountReducer";
import postPrintPaperReceipt from "./account/postPrintPaperReceiptReducer";
import consumptionLocations from "./consumptionLocations/consumptionLocationsReducer";
import contracts from "./contract/contractsReducer";
import contract from "./contract/contractReducer";
import contractStatistics from "./contract/contractStatisticsReducer"
import electricityProducts from "./product/electricityProductsReducer";
import competitors from "./competitors/competitorsReducer";
import gasCompetitors from "./competitors/gasCompetitorsReducer";
import gasProducts from "./product/gasProductsReducer";
import footer from "./menu/footerReducer";
import banks from "./contract/banksReducer";
import pendingContracts from "./contract/pendingContractsReducer";
import bonusCards from "./card/bonusCardsReducer";
import bindBonusCard from "./card/bindBonusCardReducer";
import bindCoopCard from "./card/bindCoopCardReducer";
import orderBonusCard from "./card/orderBonusCardReducer";
import invoicePayments from "./invoice/paymentsReducer";
import electricityInvoices from "./invoice/electricityInvoicesReducer";
import paymentCardInvoices from "./invoice/paymentCardInvoicesReducer";
import invoice from "./invoice/invoiceReducer";
import bonusCard from "./card/getBonusCardReducer";
import cardQR from "./card/getCardQR";
import invoicePaymentStatus from "./invoice/invoicePaymentStatusReducer";
import cardsDiscounts from "./discount/cardsDiscountsReducer";
import address from "./address/addressReducer";
import communityProjectStatistics from "./communityProject/communityProjectReducer";
import customerContribution from "./communityProject/customerContributionReducer";
import communityProjectRegister from "./communityProject/communityProjectRegisterReducer";
import communityProjectPrepareRegisterCompany from "./communityProject/communityProjectPrepareRegisterCompanyReducer";
import downloadCommunityProjectContractPreviewPdf from "./communityProject/downloadCommunityProjectContractPreviewPdfReducer";
import putAccount from "./account/putAccountReducer";
import putContacts from "./contact/putContactsReducer";
import fuelTaxiOrder from "./fuelTaxiOrder/fuelTaxiOrderReducer";
import bonuscardTransactions from "./transactions/bonuscardTransactionsReducer";
import allBonuscardTransactions from "./transactions/allBonuscardTransactionsReducer";
import servicePoints from "./station/servicePointsReducer";
import putContract from "./contract/putContractReducer";
import updateContract from "./contract/updateContractReducer";
import wholesaleFuels from "./fuelTaxiOrder/wholesaleFuelsReducer";
import countyCommunityProjectStatistics from "./communityProject/countyContributionReducer";
import setAgentRole from "./account/setAgentRoleReducer";
import mainContact from "./contact/mainContactReducer";
import salepoints from "./station/salepointReducer";
import salepointSearch from "./station/salepointSearchReducer";
import salepointTaxonomies from "./station/salepointTaxonomyReducer";
import postFinalizeContract from "./contract/postFinalizeContract";
import unsubscribeFromEmails from "./subscription/unsubscribeReducer";
import subscription from "./subscription/subscriptionReducer";
import consumptionDetails from "./consumptionLocations/consumptionDetailsReducer";
import setBonuscardToPrimary from "./card/setBonuscardToPrimaryReducer";
import bonuscardQR from "./card/getBonusCardQR";
import cards from "./card/cardsReducer";
import allCards from "./card/allCardsReducer";
import orderCard from "./card/orderCardReducer";
import updateCards from "./card/updateCardsReducer";
import payments from "./payment/paymentsReducer";
import stockExchangePriceAlert from "./stockExchangePriceAlert/stockExchangePriceAlertReducer";
import putAlert from "./stockExchangePriceAlert/putAlertReducer";
import ui from "./ui/uiReducer";
import consumptionDetailsFile from "./consumptionLocations/consumptionDetailsFileReducer";
import localeGroup from "./locale/localeGroupReducer";
import nordpoolPrices from "./stockPrice/nordpoolPriceReducer";
import userNotifications from "./notification/userNotificationsReducer";
import unreadUserNotifications from "./notification/userNotificationsUnreadReducer";
import putUserNotificationsAsRead from "./notification/putUserNotificationsAsReadReducer";
import putAllUserNotificationsAsRead from "./notification/putAllUserNotificationsAsReadReducer";
import localeGroupContent from "./locale/localeGroupContentReducer";
import putLocale from "./locale/putLocaleReducer";
import deleteLocale from "./locale/deleteLocaleReducer";
import localeClearCache from "./locale/localeClearCacheReducer";
import localeUnfinished from "./locale/localeUnfinishedReducer";
import localeSyncToTest from "./locale/localeSyncToTestReducer";
import userGroupCode from "./account/userGroupCodeReducer";
import postAuthorizedUser from "./account/postAuthorizedUserReducer";
import authorizedUser from "./account/authorizedUserReducer";
import userRoleInCompany from "./account/userRoleInCompanyReducer"
import putAuthorizedUser from "./account/putAuthorizedUserReducer";
import canSetAuthorization from "./account/canSetAuthorizationReducer";
import updateUserGroupCode from "./card/updateUserGroupReducer";
import productionDetails from "./consumptionLocations/productionDetailsReducer";
import productionDetailsFile from "./consumptionLocations/productionDetailsFileReducer";
import invoicePaymentMethods from "./invoice/invoicePaymentMethodsReducer";
import activateGreenEnergy from "./contract/postActivateGreenEnergyReducer";
import deactivateGreenEnergy from "./contract/postDeactivateGreenEnergyReducer";
import stockPriceAlerts from "./stockExchangePriceAlert/stockPriceAlertsReducer";
import cardTransactions from "./transactions/cardTransactionsReducer";
import allCardTransactions from "./transactions/allCardTransactionsReducer";
import cardNumbers from "./card/cardNumbersReducers";
import postPrepareSignature from "./digitalSignature/postPrepareSignatureReducer";
import postFinalizeSignature from "./digitalSignature/postFinalizeSignatureReducer";
import postPrepareSignatureSmartId from "./digitalSignature/postPrepareSignatureSmartIdReducer";
import postFinalizeSignatureSmartId from "./digitalSignature/postFinalizeSignatureSmartIdReducer";
import postPrepareSignatureMobileId from "./digitalSignature/postPrepareSignatureMobileIdReducer";
import postFinalizeSignatureMobileId from "./digitalSignature/postFinalizeSignatureMobileIdReducer";
import invoicePdf from "./invoice/invoicePdfReducer"
import validatePersonCreditScore from "./card/validatePersonCreditScoreReducer";
import validateCompanyCreditScore from "./card/validateCompanyCreditScoreReducer";
import enablePartnerApiAccess from "./partnerApi/enablePartnerApiAccessReducer";
import disablePartnerApiAccess from "./partnerApi/disablePartnerApiAccessReducer";
import postProcessPersonCardApplication from "./card/postProcessPersonCardApplicationReducer";
import postPendingCompanyCardApplication from "./card/postPendingCompanyCardApplicationReducer";
import postAcceptedPersonCardApplication from "./card/postAcceptedPersonCardApplicationReducer";
import postAcceptedCompanyCardApplication from "./card/postAcceptedCompanyCardApplicationReducer";
import postFinalizeAcceptedCardApplication from "./card/postFinalizeAcceptedCardApplicationReducer";
import paymentCardStatus from "./card/paymentCardStatusReducer";
import downloadContractDigiDoc from "./contract/downloadContractDigiDocReducer";
import downloadContractPdf from "./contract/downloadContractPdfReducer";
import getDeviceRegistrations from "./pushNotification/getDeviceRegistrationsReducer";
import postDeviceRegistration from "./pushNotification/postDeviceRegistrationReducer";
import deleteDeviceRegistration from "./pushNotification/deleteDeviceRegistrationReducer";
import resetDeviceRegistration from "./pushNotification/resetDeviceRegistrationReducer";
import hubInstallations from "./pushNotification/hubInstallationsReducer";
import pushNotificationSettings from "./pushNotification/pushNotificationSettingsReducer";
import savePushNotificationSettings from "./pushNotification/savePushNotificationSettingsReducer";
import downloadPersonCardContractPreviewPdf from "./card/downloadPersonCardContractPreviewPdfReducer";
import downloadCompanyCardContractPreviewPdf from "./card/downloadCompanyCardContractPreviewPdfReducer";
import fetchReceiptPdf from "./transactions/fetchReceiptPdfReducer";
import fetchBonusCardReceiptsPdf from "./transactions/fetchBonusCardReceiptsPdfReducer";
import fetchCardReceiptsPdf from "./transactions/fetchCardReceiptsPdfReducer";
import contractLimitApplications from "./contract/contractLimitApplicationsReducer";
import acceptNewContractLimit from "./contract/acceptNewContractLimitReducer";
import rejectNewContractLimit from "./contract/rejectNewContractLimitReducer";
import postActivateCard from "./card/postActivateCardReducer";
import downloadCommunityProjectContractDigiDoc from "./communityProject/downloadCommunityProjectContractDigiDocReducer";
import responsiblePersons from "./responsiblePersons/responsiblePersonsReducer";
import authorizedContracts from "./account/fetchAuthorizedContractsReducer";
import bookings from "./bookings/bookingsReducer";
import menuSelection from "./menu/setMenuSelectionReducer";
import orderPlasticCard from "./card/orderPlasticCardReducer";
import usersWithRole from "./account/usersWithRoleReducer";
import putUserWithRole from "./account/putUserWithRoleReducer";
import clientContractConsumptionLocations from "./consumptionLocations/clientContractConsumptionLocationsReducer";
import contractPackageChange from "./contract/confirmPackageChangeReducer";
import extendContract from "./contract/extendContractReducer";
import addNewNotificationToUsers from "./notification/addNewNotificationToUsersReducer";
import marketingNotifications from "./notification/marketingNotificationsReducer";
import localeSearchResults from "./locale/fetchSearchResultsReducer";
import digitalStampsSummary from "./digitalStamps/getDigitalStampsSummaryReducer";
import digitalStampsTransactions from "./digitalStamps/getDigitalStampsTransactionsReducer";
import convertStamps from "./digitalStamps/postConvertStampsReducer";
import postAddCustomer from "./digitalStamps/postAddCustomer";
import postTransferToCard from "./digitalStamps/postTransferToCardReducer";
import userCompanyRoles from "./account/getUserCompanyRolesReducer";
import userStatus from "./account/userStatusReducer";
import banner from "./banner/bannerReducer";
import setBannerHidden from "./banner/setBannerHiddenReducer";
import resetBannerForEveryone from "./banner/resetBannerForEveryoneReducer";
import basketballCommunityProjectRegister from "./basketballCommunityProject/basketballCommunityProjectRegisterReducer";
import basketballProjectCustomerContribution from "./basketballCommunityProject/basketballCommunityProjectCustomerContributionReducer";
import basketballProjectStatistics from "./basketballCommunityProject/basketballCommunityProjectStatisticsReducer.jsx";
import basketballProjectClubs from "./basketballCommunityProject/basketballCommunityProjectClubsReducer.jsx";
import digitalStampsProducts from "./digitalStamps/getProductsClientReducer";
import digitalStampsCampaignPrizes from "./digitalStamps/getCampaignPrizesClientReducer";
import digitalStampsRedeemCampaignPrize from "./digitalStamps/postRedeemCampaignPrizeClientReducer";
import convertProductClient from "./digitalStamps/convertProductClientReducer";
import stations from "./digitalStamps/getStationsReducer";
import sendTestNotification from "./pushNotification/sendTestNotificationReducer";
import testNotifications from "./pushNotification/testNotificationsReducer";
import appContainer from "./appContainer/appContainerReducer";
import recentElectricityInvoices from "./invoice/recentElectricityInvoicesReducer";
import purchaseRight from "./fuelTaxiOrder/fetchPurchaseRightReducer";
import activeOffers from "./offer/getActiveOffersReducer";
import marketingNotificationAccountCount from "./notification/marketingNotificationAccountCountReducer";
import prepareElmoContractOfferSinging from "./contract/prepareElmoContractOfferSingingReducer";
import finalizeElmoContractOfferSinging from "./contract/finalizeElmoContractOfferSingingReducer";
import verifyUserSigningRight from "./account/verifyUserSigningRightReducer";
import elmoContractDetails from "./contract/fetchContractDetailsReducer";
import signedContracts from "./contract/fetchSignedContractsReducer";
import rejectOffer from "./offer/rejectOfferReducer";
import downloadPdfFile from "./common/downloadPdfFileReducer";
import bonuscardStatus from "./digitalStamps/getBonuscardStatusReducer";
import postBindBonuscard from "./digitalStamps/postBindBonuscardReducer";
import createFamilyGroup from "./familyGroup/createFamilyGroupReducer";
import familyGroup from "./familyGroup/fetchFamilyGroupReducer";
import acceptFamilyGroupInvitation from "./familyGroup/acceptInvitationReducer";
import rejectFamilyGroupInvitation from "./familyGroup/rejectInvitationReducer";
import deleteFamilyGroup from "./familyGroup/deleteGroupReducer";
import evChargingOffers from "./evCharging/fetchEvChargingOffersReducer";
import evChargingOfferAgentPdf from "./evCharging/fetchEvChargingOfferAgentPdfReducer";
import evChargingContractPdf from "./evCharging/downloadEvChargingContractPdfReducer";
import evChargingContractPrepare from "./evCharging/postPrepareEvChargingContractForSigningReducer";
import evChargingContractFinalize from "./evCharging/postFinalizeEvChargingContractReducer";
import sendHintEmail from "./hint/sendHintReducer";
import hintHistory from "./hint/getHintHistoryReducer";
import hintAgents from "./hint/getHintHistoryAgentListReducer"
import postContact from "./account/postContactReducer";
import sendMarketingSms from "./notification/sendMarketingSms"
import marketingSmsMessageLog from "./notification/fetchMarketingSmsMessageLogReducer"
import smsTemplates from "./smsTemplates/getSmsTemplatesReducer.jsx"
import postSmsTemplate from "./smsTemplates/postSmsTemplateReducer";
import putSmsTemplate from "./smsTemplates/putSmsTemplateReducer";
import postActionLog from "./actionLog/postActionLogReducer";
import activatePartnerApi from "./partnerApi/activatePartnerApiReducer";
import deactivatePartnerApi from "./partnerApi/deactivatePartnerApiReducer";
import partnerApiAccess from "./partnerApi/hasPartnerApiAccessReducer";
import hasGasoPermission from "./contract/hasGasoPermissionReducer";
import registerGasoPermission from "./contract/registerGasoPermissionReducer";
import postPrepareSignatureEParakstsMobile from "./digitalSignature/postPrepareSignatureEParakstsMobileReducer";
import postFinalizeSignatureEParakstsMobile from "./digitalSignature/postFinalizeSignatureEParakstsMobileReducer";
import removeFamilyGroupMember from "./familyGroup/removeFamilyGroupMemberReducer";
import leaveFamilyGroup from "./familyGroup/leaveFamilyGroupReducer";
import gasoMeter from "./gasoMeter/gasoMeterReducer";
import submitGasoMeter from "./gasoMeter/submitGasoMeterReducer";
import activeTicketCount from "./campaign/getActiveTicketCountReducer";
import spinWheel from "./campaign/spinWheelReducer";
import customerCouponBalance from "./customerCoupon/getCustomerCouponBalanceReducer";
import appCampaigns from "./campaign/getAppCampaignsReducer";
import upsertAppCampaign from "./campaign/insertOrUpdateAppCampaignReducer";
import fuelPlusElectricityInfo from "./campaign/fuelPlusElectricityInfoReducer";
import fuelPlusElectricityRegister from "./campaign/fuelPlusElectricityRegisterReducer";
import wrappedInfo from "./campaign/wrappedInfoReducer";
import offers from "./campaign/getOffersReducer";
import upsertOffer from "./campaign/insertOrUpdateOfferReducer";

const allReducers = combineReducers({
    intl: intlReducer,
    auth,
    addressCounties,
    addressParishes,
    addressCities,
    addressStreets,
    refreshJWT,
    roles,
    contact,
    contacts,
    account,
    postPrintPaperReceipt,
    contracts,
    electricityProducts,
    competitors,
    consumptionLocations,
    gasCompetitors,
    gasProducts,
    setRole,
    banks,
    footer,
    pendingContracts,
    bonusCards,
    payments,
    cardQR,
    cards,
    allCards,
    orderCard,
    updateCards,
    bindBonusCard,
    bindCoopCard,
    orderBonusCard,
    invoicePayments,
    electricityInvoices,
    paymentCardInvoices,
    invoice,
    bonusCard,
    invoicePaymentStatus,
    cardsDiscounts,
    communityProjectStatistics,
    customerContribution,
    communityProjectRegister,
    communityProjectPrepareRegisterCompany,
    downloadCommunityProjectContractPreviewPdf,
    address,
    fuelTaxiOrder,
    putAccount,
    putContacts,
    bonuscardTransactions,
    allBonuscardTransactions,
    servicePoints,
    putContract,
    wholesaleFuels,
    setAgentRole,
    countyCommunityProjectStatistics,
    mainContact,
    salepoints,
    salepointSearch,
    salepointTaxonomies,
    postFinalizeContract,
    unsubscribeFromEmails,
    subscription,
    consumptionDetails,
    setBonuscardToPrimary,
    bonuscardQR,
    stockExchangePriceAlert,
    putAlert,
    ui,
    consumptionDetailsFile,
    fetchReceiptPdf,
    fetchCardReceiptsPdf,
    fetchBonusCardReceiptsPdf,
    localeGroup,
    nordpoolPrices,
    userNotifications,
    unreadUserNotifications,
    putUserNotificationsAsRead,
    putAllUserNotificationsAsRead,
    localeGroupContent,
    putLocale,
    deleteLocale,
    localeClearCache,
    localeUnfinished,
    localeSyncToTest,
    userGroupCode,
    updateUserGroupCode,
    postAuthorizedUser,
    authorizedUser,
    putAuthorizedUser,
    canSetAuthorization,
    productionDetails,
    productionDetailsFile,
    invoicePaymentMethods,
    activateGreenEnergy,
    deactivateGreenEnergy,
    stockPriceAlerts,
    cardTransactions,
    allCardTransactions,
    cardNumbers,
    postPrepareSignature,
    postFinalizeSignature,
    postPrepareSignatureSmartId,
    postFinalizeSignatureSmartId,
    postPrepareSignatureMobileId,
    postFinalizeSignatureMobileId,
    invoicePdf,
    validatePersonCreditScore,
    validateCompanyCreditScore,
    contract,
    enablePartnerApiAccess,
    disablePartnerApiAccess,
    postProcessPersonCardApplication,
    postPendingCompanyCardApplication,
    postAcceptedPersonCardApplication,
    postAcceptedCompanyCardApplication,
    postFinalizeAcceptedCardApplication,
    paymentCardStatus,
    downloadContractDigiDoc,
    getDeviceRegistrations,
    postDeviceRegistration,
    deleteDeviceRegistration,
    resetDeviceRegistration,
    hubInstallations,
    pushNotificationSettings,
    savePushNotificationSettings,
    downloadContractPdf,
    downloadPersonCardContractPreviewPdf,
    downloadCompanyCardContractPreviewPdf,
    contractStatistics,
    contractLimitApplications,
    acceptNewContractLimit,
    rejectNewContractLimit,
    postActivateCard,
    downloadCommunityProjectContractDigiDoc,
    updateContract,
    userRoleInCompany,
    responsiblePersons,
    authorizedContracts,
    bookings,
    menuSelection,
    orderPlasticCard,
    usersWithRole,
    putUserWithRole,
    clientContractConsumptionLocations,
    contractPackageChange,
    extendContract,
    addNewNotificationToUsers,
    marketingNotifications,
    localeSearchResults,
    userCompanyRoles,
    digitalStampsSummary,
    digitalStampsTransactions,
    convertStamps,
    postTransferToCard,
    userStatus,
    banner,
    setBannerHidden,
    resetBannerForEveryone,
    basketballCommunityProjectRegister,
    basketballProjectCustomerContribution,
    basketballProjectStatistics,
    basketballProjectClubs,
    digitalStampsProducts,
    digitalStampsCampaignPrizes,
    digitalStampsRedeemCampaignPrize,
    convertProductClient,
    postAddCustomer,
    stations,
    sendTestNotification,
    testNotifications,
    appContainer,
    recentElectricityInvoices,
    purchaseRight,
    activeOffers,
    marketingNotificationAccountCount,
    prepareElmoContractOfferSinging,
    finalizeElmoContractOfferSinging,
    verifyUserSigningRight,
    elmoContractDetails,
    signedContracts,
    evChargingOfferAgentPdf,
    evChargingOffers,
    evChargingContractPdf,
    evChargingContractPrepare,
    evChargingContractFinalize,
    rejectOffer,
    downloadPdfFile,
    bonuscardStatus,
    postBindBonuscard,
    createFamilyGroup,
    familyGroup,
    acceptFamilyGroupInvitation,
    rejectFamilyGroupInvitation,
    deleteFamilyGroup,
    sendHintEmail,
    hintHistory,
    hintAgents,
    postContact,
    sendMarketingSms,
    marketingSmsMessageLog,
    smsTemplates,
    postSmsTemplate,
    putSmsTemplate,
    postActionLog,
    activatePartnerApi,
    deactivatePartnerApi,
    partnerApiAccess,
    hasGasoPermission,
    registerGasoPermission,
    postPrepareSignatureEParakstsMobile,
    postFinalizeSignatureEParakstsMobile,
    removeFamilyGroupMember,
    leaveFamilyGroup,
    gasoMeter,
    submitGasoMeter,
    activeTicketCount,
    spinWheel,
    customerCouponBalance,
    appCampaigns,
    upsertAppCampaign,
    fuelPlusElectricityInfo,
    fuelPlusElectricityRegister,
    wrappedInfo,
    offers,
    upsertOffer
});
export default allReducers;

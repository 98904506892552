import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";
import classnames from "classnames";
import SimpleReactValidator from "simple-react-validator";

import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";

import cardImage from "../../images/card.jpg";
import cardCoop from "../../images/coop-white-small.png";
import cardVirtualmage from "../../images/card-virtual.png";
import creditCardImage from "../../images/creditcard.png";

import Loader from "./Loader";
import BaseDialog from "./BaseDialog";
import BaseFormComponent from "./BaseFormComponent";
import TextTooltip from "./TextTooltip";
import AlertMessage from "./AlertMessage";
import ReferralLink from "./ReferralLink";
import ContractJoinCard from "../cards/ContractJoinCard";
import BindCard from "../cards/BindCard";
import BindCoopCard from "../cards/BindCoopCard";
import AudiCampaignDialog from "../cards/AudiCampaignDialog";
import PersonalizeBonuscardDialog from "../cards/PersonalizeBonuscardDialog";
import { SendActionEvent } from "../../helpers/GoogleTagHelper";
import { isCreditcard } from "../../helpers/cardHelpers";
import { fetchBonusCards } from "../../actions/cardActions";
import { fetchAccount } from "../../actions/accountActions";
import {
    getCardDetailsPath,
    AudiCampaignPath,
    CardsPath,
    PersonalizeBonuscardPath
} from "../../const/routes";
import auth from "../../auth/authenticate";

import "../../styles/react-components/table.scss";

class Cards extends BaseFormComponent {
    static CARD_ACTIVE = "CARD_STATUS.OK";
    static CARD_CLOSED = "CARD_STATUS.CLOSED";
    static CARD_CLOSED_BY_CLIENT = "CARD_STATUS.CLOSED_BY_CLIENT";
    static CARD_CLOSED_WITH_STOPLIST = "CARD_STATUS.CLOSED_WITH_STOPLIST";
    static CARD_IN_PRODUCTION = "CARD_STATUS.IN_PRODUCTION";
    static CARD_LOST_OR_STOLEN = "CARD_STATUS.LOST_OR_STOLEN";
    static AGR_CARD_PREFIX = "923374221";

    constructor(props) {
        super(props);
        this.state = {
            showBindCardResponse: false,
            showBindCoopCardSuccess: false,
            isBonusCardDialogOpen: false,
            isBindBonusCardDialogOpen: false,
            isBindCoopCardDialogOpen: false,
            bindBonusCardNumber: "",
            bonusCardId: null,
            referral: ReferralLink.getReferralLinkParams(),
            isAudiCampaign: window.location.pathname.includes(AudiCampaignPath),
            isPersonalization: window.location.pathname.includes(PersonalizeBonuscardPath),
            isCompany: auth.isCompany()
        };

        this.closeBindCoopCardDialog = this.closeBindCoopCardDialog.bind(this);
        this.closeBindBonusCardDialog = this.closeBindBonusCardDialog.bind(this);
        this.bindBonusCardSuccess = this.bindBonusCardSuccess.bind(this);
        this.hasValidBonusCard = this.hasValidBonusCard.bind(this);
        this.renderCardsHeader = this.renderCardsHeader.bind(this);
        this.renderCardsMobileFooter = this.renderCardsMobileFooter.bind(this);
        this.onCloseAudiCampaign = this.onCloseAudiCampaign.bind(this);
        this.onClosePersonalizationDialog = this.onClosePersonalizationDialog.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    componentDidMount() {
        if (this.state.referral) {
            if (!this.state.referral.isRedirectDone) {
                ReferralLink.setRedirectDone();
                this.setState({
                    isBonusCardDialogOpen: true,
                    bonusCardId: null,
                });
            }
        }
        this.props.fetchBonusCards();
        this.props.fetchAccount();
    }

    onSubmitBindBonusCard(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        this.props.bindBonusCard(this.state[Cards.BindBonusCardNumber]).then(this.bindBonusCardSuccess);
    }

    componentDidUpdate(oldProps) {
        if (!this.props.bonusCardOrderFetching && oldProps.bonusCardOrderFetching && this.state.isBonusCardDialogOpen) {
            this.setState({ isBonusCardDialogOpen: false });
        }
        if (this.props.bonusCardOrderFetched && !oldProps.bonusCardOrderFetched) {
            this.props.fetchBonusCards();
        }

        if (!this.props.bonusCardBindFetching && oldProps.bonusCardBindFetching && this.props.bonusCardBindFetched) {
            this.props.fetchBonusCards();

            this.setState({
                showBindCardResponse: true,
                isBindBonusCardDialogOpen: false,
                isPersonalization: false,
                isAudiCampaign: false
            });
        }

        if (!this.props.coopCardBindFetching && oldProps.coopCardBindFetching && this.props.coopCardBindFetched) {
            this.props.fetchBonusCards();

            this.setState({
                showBindCoopCardSuccess: true,
                isBindCoopCardDialogOpen: false
            });
        }
    }

    toggleBonusCardDialog(cardId) {
        if (!this.state.isBonusCardDialogOpen) {
            SendActionEvent("CardCreating");
        }

        this.setState({
            bonusCardId: cardId,
            isBonusCardDialogOpen: !this.state.isBonusCardDialogOpen
        });
    }

    toggleBindBonusCardDialog() {
        this.setState({
            isBindBonusCardDialogOpen: !this.state.isBindBonusCardDialogOpen
        });
    }

    toggleBindCoopCardDialog() {
        this.setState({
            isBindCoopCardDialogOpen: !this.state.isBindCoopCardDialogOpen
        });
    }

    bindBonusCardSuccess() {
        this.closeBindBonusCardDialog();
        this.props.fetchBonusCards();
    }

    closeBonusCardDialog() {
        this.setState({
            isBonusCardDialogOpen: false
        });
        if (this.state.referral) {
            this.props.history.push("/cards");
        }
    }

    closeBindBonusCardDialog() {
        this.setState({
            isBindBonusCardDialogOpen: false
        });
    }

    closeBindCoopCardDialog() {
        this.setState({
            isBindCoopCardDialogOpen: false
        });
    }

    renderMessage(titleId, messageId) {
        return (
            <div className="message message--wide up down">
                <span className="icon icon-info align" />
                <div className="message__head">
                    <FormattedMessage
                        tagName="b"
                        id={titleId}
                    />
                </div>
                <div className="message__description">
                    <FormattedMessage
                        tagName="p"
                        id={messageId}
                    />
                </div>
            </div>
        );
    }

    isCardActive(status) {
        return status === Cards.CARD_ACTIVE;
    }

    isCardInProduction(status) {
        return status === Cards.CARD_IN_PRODUCTION;
    }

    isCardExpired(validToDate) {
        let now = moment();

        return validToDate.diff(now) < 0;
    }

    isCardClosedByClient(status) {
        return status === Cards.CARD_CLOSED_BY_CLIENT;
    }

    isCardExtendable(card, isCardActive, isCardExpired) {
        const validTo = moment(card.validToDate);
        const extendAvailable = moment(validTo).subtract(2, 'months');
        let isAGRCard = card.number.startsWith(Cards.AGR_CARD_PREFIX);

        if (isAGRCard) {
            return !isCardExpired && isCardActive
        }

        return !isCardExpired && isCardActive && moment().diff(extendAvailable) > 0;
    }

    hasValidBonusCard() {
        const { bonusCards } = this.props;
        let now = moment();
        let hasValidCard = bonusCards.filter(bc => (bc.status === Cards.CARD_ACTIVE || bc.status === Cards.CARD_IN_PRODUCTION)
            && moment(bc.validToDate).diff(now) > 0).length > 0;
        return hasValidCard;
    }

    getCardImageSrc(cardIsVirtual, cardIsCreditCard, cardIsCoop) {
        if (cardIsCoop) {
            return cardCoop;
        }
        if (cardIsCreditCard) {
            return creditCardImage;
        }
        if (cardIsVirtual) {
            return cardVirtualmage;
        }

        return cardImage;
    }

    onCardRowClick(card) {
        const isActiveCard = this.isCardActive(card.status);

        if (isActiveCard) {
            this.props.history.push(getCardDetailsPath(card.number));
        }
    }

    onCloseAudiCampaign() {
        this.setState({ isAudiCampaign: false });
        this.props.history.push(CardsPath);
    }

    onClosePersonalizationDialog() {
        this.setState({ isPersonalization: false });
        this.props.history.push(CardsPath);
    }

    renderCardRow(card, index) {
        const validTo = moment(card.validToDate);

        let isCardActive = this.isCardActive(card.status);
        let isCardExpired = this.isCardExpired(validTo);
        let isCardClosedByClient = this.isCardClosedByClient(card.status);
        let isCardInProduction = this.isCardInProduction(card.status);
        let isCardExtendable = this.isCardExtendable(card, isCardActive, isCardExpired);
        let isCreditCard = isCreditcard(card.number);
        let isCoopCard = card.number.startsWith("92339990000");

        const detailClick = isCardActive ? this.onCardRowClick.bind(this, card) : null;
        const isCardDisabled = !isCardActive || isCardExpired || isCardInProduction;
        return (
            <tr
                className={classnames(
                    {
                        primary: isCardActive || isCardClosedByClient,
                        disabled: isCardExpired || isCardInProduction,
                        'primary-card': card.isPrimary
                    }
                )}
                key={"card" + index}
            >
                {
                    card.name &&
                    <td className={classnames("hidden-desktop wrap",{'clickable': isCardActive})} onClick={detailClick}>
                        <Typography
                            use="headline4"
                            className="mdc-typography mdc-theme--primary mb-mobile-10"
                        >
                            {card.name}
                        </Typography>
                    </td>
                }
                <td className={classnames("flip",{'clickable': isCardActive})} onClick={detailClick}>
                    <img
                        src={this.getCardImageSrc(card.isVirtual, isCreditCard, isCoopCard)}
                        className={isCardDisabled ? 'card-disabled' : ""}
                        alt={isCardActive ? "card" : "card-disabled"}
                    />
                </td>

                <td className={classnames("card-info hidden-desktop",{'clickable': isCardActive})} onClick={detailClick}>
                    <p className="mdc-theme--primary main-content">
                        {card.number}
                    </p>
                    <p className="mdc-theme--primary main-content">
                        {this.renderCardStatusMobile(card.status, validTo)}
                    </p>
                    <p className="mdc-theme--primary main-content">
                        {validTo.format("DD.MM.YYYY")}
                    </p>
                </td>
                {
                    (card.isPrimary || isCardExtendable) &&
                    <td className="secondary-content hidden-desktop card-actions card-actions--col">
                        {card.isPrimary && !this.state.isCompany &&
                            <TextTooltip className="tooltip-text--on-left-side" label="Cards.PrimaryCard.Label" content="Cards.ConnectedToId" />
                        }
                        {isCardExtendable &&
                            <Button
                                className="other align-right align-right--desktop mdc-button--text mdc-button--secondary"
                                onClick={this.toggleBonusCardDialog.bind(this, card.id)}
                            >
                                <FormattedMessage id="Cards.Button.Extend" />
                            </Button>
                        }
                    </td>
                }

                <td className={classnames("hidden-mobile",{'clickable': isCardActive})} onClick={detailClick}>
                    <h4 className="mdc-theme--secondary uppercase">
                        <FormattedMessage id="Cards.CardNumber" />
                    </h4>
                    <p className="mdc-theme--primary main-content">
                        {card.number}
                    </p>
                </td>
                <td className={classnames("secondary-content hidden-mobile",{'clickable': isCardActive})} onClick={detailClick}>
                    <p>
                        <FormattedMessage id="Cards.Name" />
                        &nbsp;
                        <b>{card.name}</b>
                    </p>
                    <p>
                        <FormattedMessage id="Cards.Status" />
                        &nbsp;
                        {this.renderCardStatus(card.status, validTo)}
                    </p>
                </td>
                <td className={classnames("secondary-content hidden-mobile",{'clickable': isCardActive})} onClick={detailClick}>
                    <FormattedMessage tag="p" id="Cards.ValidTo" />
                    <p>
                        <b>{validTo.format("DD.MM.YYYY")}</b>
                    </p>
                </td>

                <td className="secondary-content hidden-mobile">
                    {card.isPrimary && !this.state.isCompany &&
                        <TextTooltip label="Cards.PrimaryCard.Label" content="Cards.ConnectedToId" />
                    }
                </td>

                <td className="secondary-content hidden-mobile">
                    {isCardExtendable &&
                        <Button
                            className="other align-right align-right--desktop mdc-button--text mdc-button--secondary"
                            onClick={this.toggleBonusCardDialog.bind(this, card.id)}
                        >
                            <FormattedMessage id="Cards.Button.Extend" />
                        </Button>
                    }
                </td>
            </tr >
        );
    }

    renderCardStatusMobile(status, validTo) {
        if (this.isCardExpired(validTo)) {
            return <FormattedMessage id="Cards.Expired" />;
        }


        if (this.isCardActive(status)) {
            return (
                <FormattedMessage id="Cards.Active" />
            );
        }

        if (this.isCardClosedByClient(status)) {
            return (
                <FormattedMessage id="Cards.Closed" />
            );
        }

        if (this.isCardInProduction(status)) {
            return (
                <FormattedMessage id="Cards.InProduction" />
            );
        }

        return <FormattedMessage id="Cards.Expired" />;
    }

    renderCardStatus(status, validTo) {
        if (this.isCardExpired(validTo)) {
            return <FormattedMessage tagName="b" id="Cards.Expired" />;
        }


        if (this.isCardActive(status)) {
            return (
                <b className="mdc-theme--done">
                    <FormattedMessage id="Cards.Active" />
                </b>
            );
        }

        if (this.isCardClosedByClient(status)) {
            return (
                <b className="mdc-theme--alert">
                    <FormattedMessage id="Cards.Closed" />
                </b>
            );
        }

        if (this.isCardInProduction(status)) {
            return (
                <b className="mdc-theme--production">
                    <FormattedMessage id="Cards.InProduction" />
                </b>
            );
        }

        return <FormattedMessage tagName="b" id="Cards.Expired" />;
    }

    renderCardsMobileFooter() {
        const { bonusCardsFetched } = this.props;

        return (
            <div className="hidden-desktop">
                {bonusCardsFetched && !this.hasValidBonusCard() && <Button
                    className="mdc-button--secondary mr-15 mb-mobile-10"
                    outlined
                    onClick={this.toggleBonusCardDialog.bind(this, undefined)}
                >
                    <FormattedMessage id="Cards.Order.BonusCard" />
                </Button>}
                <Button
                    className="mdc-button--secondary mb-mobile-10"
                    outlined
                    onClick={this.toggleBindBonusCardDialog.bind(this)}
                >
                    <FormattedMessage id="Cards.BindBonusCard" />
                </Button>
                <Button
                    className="mdc-button--secondary mb-mobile-10"
                    outlined
                    onClick={this.toggleBindCoopCardDialog.bind(this)}
                >
                    <FormattedMessage id="Cards.BindCoopCard" />
                </Button>
            </div>
        );
    }

    renderCardsHeader() {
        const { bonusCardsFetched } = this.props;

        return (
            <div className="d-flex align-items-center justify-content-end">
                <div className="right hidden-mobile">
                    <Button
                        className="mr-15 mb-mobile-10"
                        outlined
                        onClick={this.toggleBindCoopCardDialog.bind(this)}
                    >
                        <FormattedMessage id="Cards.BindCoopCard" />
                    </Button>

                    {bonusCardsFetched && !this.hasValidBonusCard() && <Button
                        className="mr-15 mb-mobile-10"
                        outlined
                        onClick={this.toggleBonusCardDialog.bind(this, undefined)}
                    >
                        <FormattedMessage id="Cards.Order.BonusCard" />
                    </Button>}
                    <Button
                        className="mb-mobile-10"
                        outlined
                        onClick={this.toggleBindBonusCardDialog.bind(this)}
                    >
                        <FormattedMessage id="Cards.BindBonusCard" />
                    </Button>
                </div>
                {this.renderBindBonusCardDialog()}
                {this.renderBindCoopCardDialog()}
                {this.renderAudiCampaignDialog()}
                {this.renderPersonalizationDialog()}
                <BaseDialog
                    isOpen={this.state.isBonusCardDialogOpen}
                    onClose={this.closeBonusCardDialog.bind(this)}
                >
                    <ContractJoinCard bonusCardId={this.state.bonusCardId} isEncrypted={!!this.state.referral && !!this.state.bonusCardId} />
                </BaseDialog>
            </div>
        );
    }

    renderBindBonusCardDialog() {
        return (
            <BaseDialog
                isOpen={this.state.isBindBonusCardDialogOpen}
                onClose={this.closeBindBonusCardDialog.bind(this)}
            >
                <BindCard />
            </BaseDialog>
        );
    }

    renderBindCoopCardDialog() {
        return (
            <BaseDialog
                isOpen={this.state.isBindCoopCardDialogOpen}
                onClose={this.closeBindCoopCardDialog.bind(this)}
            >
                <BindCoopCard/>
            </BaseDialog>
        );
    }

    renderAudiCampaignDialog() {
        return (
            <BaseDialog
                isOpen={this.state.isAudiCampaign}
                onClose={this.onCloseAudiCampaign}
            >
                <AudiCampaignDialog />
            </BaseDialog>
        )
    }

    renderPersonalizationDialog() {
        return (
            <BaseDialog
                isOpen={this.state.isPersonalization}
                onClose={this.onClosePersonalizationDialog}
            >
                <PersonalizeBonuscardDialog />
            </BaseDialog>
        )
    }

    render() {
        const { bonusCards } = this.props;
        let activeCards = bonusCards
            .filter(bc => bc.status === Cards.CARD_ACTIVE)
            .sort((c1, c2) => {
                // Primary cards first
                if (c1.isPrimary && !c2.isPrimary) return -1;
                if (!c1.isPrimary && c2.isPrimary) return 1;

                // Then order by end date
                if (moment(c1.validToDate).isBefore(c2.validToDate)) return 1;
                if (moment(c1.validToDate).isAfter(c2.validToDate)) return -1;

                // If end date is the same, order by card number
                return c1.number - c2.number;
            });
        let inProductionCards = bonusCards.filter(bc => bc.status === Cards.CARD_IN_PRODUCTION);
        let closedByClientCards = bonusCards.filter(bc => bc.status === Cards.CARD_CLOSED_BY_CLIENT);
        return (
            <Grid className="mdc-layout-grid--base mb-60 mt-20">

                {this.state.showBindCardResponse && this.props.bonusCardBindFetched &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_DONE}
                            isSmall={true}
                            scrollToMessage={true}
                            title={<FormattedMessage id="Cards.BindBonusCard.Success" />} />
                    </GridCell>}
                {this.state.showBindCoopCardSuccess && this.props.coopCardBindFetched &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_DONE}
                            isSmall={true}
                            scrollToMessage={true}
                            title={<FormattedMessage id="Cards.BindCoopCard.Success" />} />
                    </GridCell>}
                {this.props.bonusCardOrderFetching && <GridCell span={12}><Loader type={Loader.TYPE_CENTER} /></GridCell>}
                {this.state.showOrderCardResponse && this.props.bonusCardOrderFetched &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_DONE}
                            title={
                                <FormattedMessage id={this.props.isExtendCard ? "Cards.Order.SuccessExtend" : "Cards.Order.Success"} />
                            } isSmall={true} />
                    </GridCell>}
                {this.state.showOrderCardResponse && this.props.bonusCardOrderError && !this.hasValidBonusCard() && <GridCell span={12}>
                    <AlertMessage type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id={this.props.isExtendCard ? "Cards.Order.Failure.TitleExtend" : "Cards.Order.Failure.Title"} />}
                        description={<FormattedMessage id="Cards.Order.Failure.Details" />}
                        isSmall={true} />
                </GridCell>}
                {this.state.showOrderCardResponse && this.props.bonusCardOrderError && this.hasValidBonusCard() && <GridCell span={12}>
                    <AlertMessage type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id={"Cards.Order.Failure.Already.Exists"} />} />
                </GridCell>}
                <GridCell span={12}>
                    <main>
                        {!this.state.isCompany && this.renderCardsHeader()}
                        {this.props.bonusCardsFetching ? (
                            <Loader type={Loader.TYPE_CENTER} />
                        ) : (
                                <div className="mt-45 mt-mobile-30">
                                    <table className="table table--list cards">
                                        <tbody>
                                            {activeCards && activeCards.map((c, i) => this.renderCardRow(c, i))}
                                            {inProductionCards && inProductionCards.map((c, i) => this.renderCardRow(c, i))}
                                            {closedByClientCards && closedByClientCards.map((c, i) => this.renderCardRow(c, i))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        {this.renderCardsMobileFooter()}
                    </main>
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        bonusCards: state.bonusCards.data,
        bonusCardsFetching: state.bonusCards.fetching,
        bonusCardsFetched: state.bonusCards.fetched,
        bonusCardOrderError: state.orderBonusCard.error,
        bonusCardOrderFetching: state.orderBonusCard.fetching,
        bonusCardOrderFetched: state.orderBonusCard.fetched,
        isExtendCard: state.orderBonusCard.isExtendCard,
        bonusCardBindFetching: state.bindBonusCard.fetching,
        bonusCardBindFetched: state.bindBonusCard.fetched,
        bonusCardBindError: state.bindBonusCard.error,
        coopCardBindFetching: state.bindCoopCard.fetching,
        coopCardBindFetched: state.bindCoopCard.fetched,
        coopCardBindError: state.bindCoopCard.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchBonusCards,
        fetchAccount
    }, dispatch);
}

export default withRouter(injectIntl(connect(mapStateToProps, matchDispatchToProps)(Cards)));

import EeIdCodeValidator from "./EeIdCodeValidator";
import LvIdCodeValidator from "./LvIdCodeValidator";
import LtIdCodeValidator from "./LtIdCodeValidator";

const countryToValidateIdCode = {
    'EE': new EeIdCodeValidator(),
    'LV': new LvIdCodeValidator(),
    'LT': new LtIdCodeValidator()
};

const IdCodeValidatorRule = {
    rule() {
        return {
            idCode: {
                message: 'The :attribute must be a valid identification code.',
                rule: (val, params) => {
                    let countryCode = params[0];

                    if (!countryCode) {
                        countryCode = 'EE';
                    }

                    let validator = countryToValidateIdCode[countryCode.toUpperCase()];

                    if (!validator) {
                        return false;
                    }

                    return validator.validate(val); }
            }
        };
    }
};

export default IdCodeValidatorRule;
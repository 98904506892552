import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const OFFERS = "OFFERS";
export const UPSERT_OFFER = "UPSERT_OFFER";

export const getOffers = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: OFFERS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/campaign/offers`)
    };
}

export const insertOrUpdateOffer = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: UPSERT_OFFER,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/campaign/offers`, model)
    };
}

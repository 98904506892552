import React from "react";
import { Typography } from "@rmwc/typography";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Grid, GridCell, GridInner } from "@rmwc/grid";

import {
    fetchElectricityInvoice,
    downloadElectricityInvoicePdf,
    fetchInvoicePdfUri,
    downloadElectricityInvoicePdfByUri
} from "../actions/invoiceActions";

import AlertMessage from "../components/common/AlertMessage";
import Loader from "../components/common/Loader";
import { COMPANY_USER_INVOICE_MAX_LIMIT, InvoiceType } from "../const/Invoice";
import auth from "../auth/authenticate";

import {
    InvoiceBackButton,
    InvoiceHeader,
    ElectricityInvoiceServicesTable,
    InvoicePaymentButton,
    InvoicePdfButton,
    InvoiceLine
} from "../components/Invoice";
import Currency from "../components/common/Currency";

import "../styles/react-components/invoice.scss";
import { isNullOrEmpty } from "../helpers/objectHelper";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";

class InvoiceElectricity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCompany: auth.isCompany(),
            isLatvianWeb: IsLatvianWeb()
        };

        this.handleInvoicePdfButtonClick = this.handleInvoicePdfButtonClick.bind(this);
        this.renderInvoiceDetails = this.renderInvoiceDetails.bind(this);
        this.renderInvoiceTotals = this.renderInvoiceTotals.bind(this);
        this.renderAdditionalInfo = this.renderAdditionalInfo.bind(this);
    }

    componentDidMount() {
        this.props.fetchElectricityInvoice(this.props.match.params.id);
    }

    handleInvoicePdfButtonClick() {
        const { invoice } = this.props;
        if (invoice.askInvoicePdfUrl){
            fetchInvoicePdfUri(invoice.id).then((data) => {
                this.props.downloadElectricityInvoicePdfByUri(data.uri);
            });
        } else {
            this.props.downloadElectricityInvoicePdf(invoice.id, invoice.number);
        }
    }

    renderInvoiceDetails() {
        const { invoice } = this.props;
        const invoiceDeadline = invoice.deadline && moment(invoice.deadline);
        const invoiceTime = invoice.invoiceTime && moment(invoice.invoiceTime);

        return (
            <GridInner>
                <GridCell span={4}>
                    <Typography
                        use="body1"
                        className="mdc-typography mdc-typography--body1--bold">
                        {invoice.clientName}
                    </Typography>
                    <Typography
                        use="body1"
                        className="mdc-typography">
                        {invoice.clientLegalAddress}
                    </Typography>
                </GridCell>
                <GridCell desktop={6} tablet={4} phone={4} className="mdc-layout-grid__cell--start-7-desktop">
                    <InvoiceLine
                        name={<FormattedMessage id="Invoice.Details.Reference" />}
                        value={invoice.referenceNumber} />
                    <InvoiceLine
                        name={<FormattedMessage id="Invoice.Details.InvoiceTime" />}
                        value={invoiceTime.format("DD.MM.YY")} />
                    <InvoiceLine
                        bold
                        name={<FormattedMessage id="Invoice.Details.Deadline" />}
                        value={invoiceDeadline.format("DD.MM.YY")} />
                </GridCell >
            </GridInner >
        );
    }

    renderInvoiceTotals() {
        const { invoice } = this.props;

        return (
            <GridInner >
                <GridCell span={4} className="mdc-layout-grid__cell--start-9-desktop mdc-layout-grid__cell--start-5-tablet">
                    {invoice.totals.map(total =>
                        <>
                            {total.taxable.map(taxable =>
                                <InvoiceLine
                                    name={<FormattedMessage id="Invoice.Totals.TotalTaxableWithVatPercentage"
                                                            values={{vat: taxable.vatPercentage}}/>}
                                    value={<Currency value={taxable.totalWithoutVat}/>}/>
                            )}
                            {total.taxable.map(taxable =>
                                <InvoiceLine
                                    name={<FormattedMessage id="Invoice.Totals.VatWithPercentage"
                                                            values={{vat: taxable.vatPercentage}}/>}
                                    value={<Currency value={taxable.totalVat}/>}/>
                            )}
                            <InvoiceLine
                                name={<FormattedMessage id="Invoice.Totals.NotTaxable"/>}
                                value={<Currency value={total.notTaxable}/>}/>
                            <InvoiceLine
                                bold
                                name={<FormattedMessage id="Invoice.Totals.Total"/>}
                                value={<Currency value={total.totalWithVat}/>}
                                className="mt-15"/>
                        </>
                    )}


                    {!!invoice.prePayment &&
                        <InvoiceLine
                            italic
                            name={<FormattedMessage id="Invoice.Totals.PreviousPrePayment" />}
                            value={<Currency value={invoice.prePayment} />} />}

                    {(!!invoice.debt || (invoice.debt === 0 && invoice.prePayment === 0)) &&
                        <InvoiceLine
                            italic
                            name={<FormattedMessage id="Invoice.Totals.PreviousDebt" />}
                            value={<Currency value={invoice.debt} />} />}

                    <InvoiceLine
                        className="item--big mt-30"
                        name={invoice.toBePaid < 0
                            ? <FormattedMessage id="Invoice.Totals.PrePaid" />
                            : <FormattedMessage id="Invoice.Totals.ToBePaidWithDate" values={{date: moment(invoice.invoiceTime).format("DD.MM.YY")}} />}
                        value={<Currency value={invoice.subTotalWithVat} />} />

                </GridCell>
            </GridInner>);
    }

    renderAdditionalInfo() {
        const { invoice: { additionalTexts, isGreenEnergy } } = this.props;

        return (
            <>
                {isGreenEnergy &&
                    <div className="mt-mobile-15">
                        <Typography use="body2" className="mdc-typography invoice__green-thanks">
                            <FormattedMessage id="Invoice.GreenEnergyThanks" />
                        </Typography>
                    </div>}
                {!isNullOrEmpty(additionalTexts) && additionalTexts.length > 0 &&
                    <div className="mt-15">
                        <Typography use="body1" className="mdc-typography mdc-typography--body1--bold">
                            <FormattedMessage id="Invoice.AdditionalInfo" />
                        </Typography>
                        {additionalTexts.map((text, i) =>
                        (<Typography key={i}
                            use="body2"
                            className="mdc-typography item--italic mt-10">
                            {text}
                        </Typography>)
                        )}
                    </div>}
            </>
        );
    }

    renderErrorMessage() {
        return (
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="Invoice.Error" />}
                />
            </GridCell>
        );
    }

    renderInvoice() {
        const { invoice, invoicePdfFetching } = this.props;

        return (
            <>
                <GridCell span={12}>
                    <GridInner>
                        {this.state.isCompany &&
                            <GridCell span={12}>
                                <AlertMessage
                                    type={AlertMessage.TYPE_NOTICE}
                                    title={
                                        <FormattedMessage
                                            id="Invoice.PaymentLimitNotice"
                                            values={{ limit: COMPANY_USER_INVOICE_MAX_LIMIT }} />
                                    } />
                            </GridCell>}
                        <GridCell desktop={7} tablet={8} phone={4} align="middle" className="invoice__header">
                            <InvoiceHeader invoice={invoice} showStatus={!this.state.isLatvianWeb} />
                        </GridCell>
                        <GridCell desktop={5} tablet={8} phone={4} className="text-right invoice__actions">
                            <InvoicePdfButton onClick={this.handleInvoicePdfButtonClick} loading={invoicePdfFetching} />
                            <InvoicePaymentButton invoice={invoice} type={InvoiceType.ElectricityAndGas}/>
                        </GridCell>
                    </GridInner>
                </GridCell>
                <GridCell span={12} className="invoice__details">
                    {this.renderInvoiceDetails()}
                </GridCell>
                <GridCell span={12} className="invoice__services">
                    <ElectricityInvoiceServicesTable invoiceItems={invoice.invoiceItems} />
                </GridCell>
                <GridCell span={12} className="invoice__totals">
                    {this.renderInvoiceTotals()}
                </GridCell>
                <GridCell span={12} className="invoice__additional-info">
                    {this.renderAdditionalInfo()}
                </GridCell>
            </>);
    }

    render() {
        const { invoice, invoiceFetching, invoiceFetched } = this.props;
        const hasInvoice = !invoiceFetching && invoiceFetched && Object.keys(invoice).length > 0;

        const renderInvoiceOrError = () => {
            return hasInvoice
                ? this.renderInvoice()
                : this.renderErrorMessage();
        }

        return (
            <Grid className="mdc-layout-grid--base mb-60 invoice invoice--electricity">
                <GridCell span={12}>
                    <InvoiceBackButton />
                </GridCell>
                {invoiceFetching
                    ? <GridCell span={12}>
                        <Loader type={Loader.TYPE_CENTER} />
                    </GridCell>
                    : renderInvoiceOrError()
                }
            </Grid >
        );
    }
}

function mapStateToProps(state) {
    return {
        invoice: state.invoice.data,
        invoiceFetching: state.invoice.fetching,
        invoiceFetched: state.invoice.fetched,
        invoicePdfFetching: state.invoicePdf.fetching,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchElectricityInvoice,
        downloadElectricityInvoicePdf,
        downloadElectricityInvoicePdfByUri,
    }, dispatch);
}

export default injectIntl(
    withRouter(connect(mapStateToProps, matchDispatchToProps)(InvoiceElectricity))
);
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import BaseFormComponent from "../components/common/BaseFormComponent";
import CardDialog from "../components/cards/CardDialog";
import NewCardDialog from "../components/cards/NewCardDialog";
import Pagination from "../components/common/Pagination";
import {
    fetchCards,
    fetchAllCards,
    activateCard,
    orderCardReset,
} from '../actions/cardActions';
import { fetchContracts } from "../actions/contractActions";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import PaymentCardFilter from "../components/cards/PaymentCardFilter";
import { StatusClosedByClient, StatusClosedStoplist, StatusInactive, StatusInProduction, StatusOk } from "../const/cardStatus";
import exportToExcel from "../components/common/ExcelExport";
import FilterButton from "../components/common/FilterButton";
import auth from "../auth/authenticate";
import { Fuel } from "../const/contractType";
import { Active } from "../const/ContractStatus";
import PaymentCardPin from "../components/cards/PaymentCardPin";
import OrderPlasticCardDialog from "../components/cards/OrderPlasticCardDialog";

import { getCardsFilter, setCardsFilter } from "../cachedFilter";
import TableList from "../components/common/TableList";
import Currency from "../components/common/Currency";
import { CompletedFormStatus } from "../const/cardApplication";
import NewCardDialogPrivateCustomer from "../components/cards/NewCardDialogPrivateCustomer";
import classNames from "classnames";

class PaymentCards extends BaseFormComponent {

    static MaxAllowedCardNameLength = 50;

    static TableHeaders = [
        {
            label: "Cards.Title.PaymentCardNumber",
            order: "cardNumber"
        },
        {
            label: "Cards.Title.CardName",
            order: "cardName"
        },
        {
            label: "Cards.Title.Status",
            order: "cardStatusXc"
        },
        {
            label: "Cards.MonthlyLimit",
            order: "limitMonthly"
        },
        {
            label: "Cards.DailyLimit",
            order: "limitDaily"
        },
        {
            label: "Cards.UnusedAmount",
            order: "unusedAmount"
        },
        {
            label: "Cards.ExpiryDate",
            order: "expiryDate",
            colspan: 3
        }
    ];

    static TableHeadersMobile = [
        {
            label: "Cards.PaymentCard",
            order: "cardNumber",
            colspan: 3
        },
    ];

    constructor(props) {
        super(props);

        const cachedFilter = getCardsFilter();

        this.state = {
            itemsToDialog: [],
            showSuccess: false,
            successMessage: "Cards.Changed",
            showError: false,
            openDialog: false,
            openNewCardDialog: false,
            isOrderPlasticCardDialogOpen: false,
            selectedPage: 1,
            showFilter: true,
            orderBy: "id",
            orderDescending: true,
            isCompany: auth.isCompany() || false,
            selectedVirtualCardNumber: null,
            filter: {
                startDate: moment().subtract(4, "months").startOf("month"),
                endDate: null,
                cardNumber: cachedFilter.cardNumber,
                cardStatusType: cachedFilter.cardStatusType,
                contractNumber: cachedFilter.contractNumber,
                cardName: cachedFilter.cardName
            }
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.closeNewCardDialog = this.closeNewCardDialog.bind(this);
        this.openNewCardDialog = this.openNewCardDialog.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.orderBy = this.orderBy.bind(this);
        this.selectCallback = this.selectCallback.bind(this);
        this.actionCallback = this.actionCallback.bind(this);
        this.orderPlasticCard = this.orderPlasticCard.bind(this);
        this.onCloseOrderPlasticCardDialog = this.onCloseOrderPlasticCardDialog.bind(this);

        this.listRef = React.createRef();
        this.contentRef = React.createRef();
    }

    componentDidMount() {
        this.fetchList()
        if (!this.props.fetchingContracts && !this.props.fetchedContracts) {
            this.props.fetchContracts(false);
        }
    }

    closeNewCardDialog() {
        this.setState({
            openNewCardDialog: false
        });
    }

    openNewCardDialog() {
        this.setState({
            openNewCardDialog: true,
            showSuccess: false,
            showError: false
        });
    }

    onCloseOrderPlasticCardDialog() {
        this.setState({
            isOrderPlasticCardDialogOpen: false
        });
    }

    closeDialog() {
        this.setState({
            openDialog: false
        });
    }

    selectCallback(indexes) {
        this.setState({
            openDialog: true,
            itemsToDialog: indexes.map((index) => {
                return this.props.cards.items[index];
            }),
            showSuccess: false,
            showError: false
        });
    }

    actionCallback(index) {
        const cardToActivate = this.props.cards?.items[index];
        const cardNumber = cardToActivate?.cardNumber;

        if (cardNumber) {
            this.props.activateCard(cardNumber)
                .then(() => {
                    this.listRef.current?.clearCache();
                    this.fetchList();
                    this.setState({ showSuccess: true, successMessage: "Cards.Activated" });
                })
                .catch(() => {
                    this.setState({ showError: true });
                });
        }
    }

    onSuccess(message) {
        this.props.orderCardReset();
        this.listRef.current?.clearCache();
        this.fetchList();
        this.setState({ showSuccess: true, successMessage: message || "Cards.Changed" });
        this.props.fetchContracts(false);
        this.scrollToRef(this.contentRef);
    }

    onPageChange({ page }) {
        this.listRef.current?.clearCache();
        this.setState(
            { selectedPage: page },
            this.fetchList.bind(this)
        );
    }

    onFilterSubmit(filter) {
        this.listRef.current?.clearCache();
        this.setState({ selectedPage: 1, filter: filter },
            this.fetchList.bind(this));
        setCardsFilter(filter);
    }

    mapCardStatus(selectedStatus, evChargingStations) {
        let status;

        if (selectedStatus === StatusInProduction) {
            status = 'InProgress';
        } else if (selectedStatus === StatusOk) {
            status = 'Ok';
        } else if (selectedStatus === StatusInactive) {
            status = 'Inactive';
        } else if (selectedStatus === StatusClosedStoplist && evChargingStations) {
            status = 'ForHomeEvUse';
        } else if (selectedStatus === StatusClosedStoplist) {
            status = 'ClosedStoplist';
        } else {
            status = 'Closed';
        }

        return status;
    }

    exportToExcel() {
        this.props.fetchAllCards(this.getFilters())
        .then((resp) => {
            this.createExcel(resp.value.data)
        }).catch(() => {
            this.setState({ showError: true });
        });
    }

    createExcel(items) {
        const cards = items.map((item) => {
            const hasEvChargingStations = item.evChargingStations.length > 0;
            item.expiryDate = moment(item.expiryDate).format("DD.MM.YYYY");
            item.lastTransactionTime = item.lastTransactionTime
                ? moment(item.lastTransactionTime).format("DD.MM.yyyy HH:mm:ss")
                : "-";
            const cardStatus = this.mapCardStatus(item.cardStatusXc, hasEvChargingStations)
            item.cardStatus = this.props.intl.formatMessage({ id: `Contract.Status.${cardStatus}` })
            return item;
        });
        const columns = [
            {
                field: 'contractNumber',
                title: this.props.intl.formatMessage({ id: "Cards.Title.ContractNumber" }),
            },
            {
                field: 'cardNumber',
                title: this.props.intl.formatMessage({ id: "Cards.Title.PaymentCardNumber" }),
            },
            {
                field: 'cardName',
                title: this.props.intl.formatMessage({ id: "Cards.Title.CardName" }),
            },
            {
                field: 'cardStatus',
                title: this.props.intl.formatMessage({ id: "Cards.Title.Status" }),
            },
            {
                field: 'limitMonthly',
                title: this.props.intl.formatMessage({ id: "Cards.MonthlyLimit" }),
            },
            {
                field: 'limitDaily',
                title: this.props.intl.formatMessage({ id: "Cards.DailyLimit" }),
            },
            {
                field: 'unusedAmount',
                title: this.props.intl.formatMessage({ id: "Cards.UnusedAmount" }),
            },
            {
                field: 'expiryDate',
                title: this.props.intl.formatMessage({ id: "Cards.ExpiryDate" }),
            },
            {
                field: 'lastTransactionTime',
                title: this.props.intl.formatMessage({ id: "Cards.Title.LastTransactionTime" }),
            }
        ];

        exportToExcel(columns, cards, "cards.xlsx")
    }

    fetchList() {
        this.props.fetchCards(this.getFilters());
    }

    getFilters() {
        return {
            ...this.state.filter,
            pageNr: this.state.selectedPage,
            limit: auth.getPageSize(),
            startDate: this.state.filter.startDate ? this.state.filter.startDate.format("YYYY-MM-DD") : null,
            endDate: this.state.filter.endDate ? this.state.filter.endDate.format("YYYY-MM-DD") : null,
            order: this.state.orderBy,
            descending: this.state.orderDescending
        }
    }

    orderBy(orderBy, orderDescending) {
        this.setState({
            orderBy: orderBy,
            orderDescending: orderDescending
        }, this.fetchList)
    }

    orderPlasticCard(cardNumber) {
        this.setState({
            isOrderPlasticCardDialogOpen: true,
            selectedVirtualCardNumber: cardNumber
        });
    }

    renderStatus(selectedStatus, hasEvChargingStations) {
        const status = this.mapCardStatus(selectedStatus, hasEvChargingStations)
        return (
            <>
                <span className={`status-label status-label--${status}`}>
                    <FormattedMessage id={`Cards.Status.${status}`} />
                </span>
            </>
        );
    }

    renderTableRowJson(item) {
        const expiryDate = moment(item.expiryDate, "YYYY-MM-DD");
        const hasEvChargingStations = item?.evChargingStations?.length > 0;

        return {
            content: [
                {
                    content: item.cardNumber
                },
                {
                    content: item.cardName,
                    className: "white-space-normal word-wrap"
                },
                {
                    content: this.renderStatus(item.cardStatusXc, hasEvChargingStations)
                },
                {
                    content: `${item.limitMonthly > 0 ? this.props.intl.formatNumber(item.limitMonthly, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }) : 0} €`
                },
                {
                    content: `${item.limitDaily > 0 ? this.props.intl.formatNumber(item.limitDaily, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }) : 0} €`
                },
                {
                    content: `${item.unusedAmount > 0 ? this.props.intl.formatNumber(item.unusedAmount, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }) : 0} €`
                },
                {
                    content: expiryDate.format("DD.MM.YYYY")
                }
            ],
            expanded: [
                {
                    colspan: 8,
                    content:
                        <>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.ContractNumber" />
                                </div>
                                <div className="bold">
                                    {item.contractNumber}
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.ContractReferenceNumber" />
                                </div>
                                <div className="bold">
                                    {item.contractReferenceNumber}
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.BookedAmount" />
                                </div>
                                <div className="bold">
                                    {`${item.bookedAmount > 0 ? this.props.intl.formatNumber(item.bookedAmount, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    }) : 0} €`}
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.LastTransactionTime" />
                                </div>
                                <div className="bold">
                                    {item.lastTransactionTime
                                        ? moment(item.lastTransactionTime).format("DD.MM.yyyy HH:mm:ss")
                                        : "-"
                                    }
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.Fuels" />
                                </div>
                                <div className="bold">
                                    <FormattedMessage id={`Cards.${item.cardFuelTypeXc}`} />
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.Products" />
                                </div>
                                <div className="bold">
                                    <FormattedMessage id={`Cards.${item.cardProductsXc}`} />
                                </div>
                            </div>
                            {item.cardStatusXc === StatusOk &&
                                <div className="border-bottom expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.Pin" />
                                    </div>
                                    <div className="bold">
                                        <PaymentCardPin cardNumber={item.cardNumber} />
                                    </div>
                                </div>
                            }
                            {!item.isVirtual &&
                                <div className="border-bottom expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.DeliveryAddress" />
                                    </div>
                                    <div className="bold">
                                        {item.deliveryAddress || "-"}
                                    </div>
                                </div>
                            }
                            <div className={classNames("expanded-item-cell", {"border-bottom": hasEvChargingStations || item.cardHolderIdCode})}>
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.CardType" />
                                </div>
                                <div className="bold">
                                <span className="pr-10"><FormattedMessage id={item.isVirtual ? "Cards.IsVirtual" : "Cards.IsPlastic"} /></span>
                                    {item.isPlasticCardApplicable &&
                                        <Button
                                            unelevated
                                            onClick={() => this.orderPlasticCard(item.cardNumber)}
                                            className="mdc-button mdc-button--tiny">
                                            <FormattedMessage id={"Cards.OrderPlasticCard"} />
                                        </Button>
                                    }
                                </div>
                            </div>
                            {hasEvChargingStations &&
                                <div className="border-bottom expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.EvChargingStations" />
                                    </div>
                                    <div className="bold">
                                        {item.evChargingStations.map(station =>
                                            <div key={station}>{station}</div>
                                        )}
                                    </div>
                                </div>
                            }
                            {item.cardHolderIdCode &&
                                <div className="expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.CardHolder" />
                                    </div>
                                    <div className="bold">
                                        {`${item.cardHolderName} (${item.cardHolderIdCode})`}
                                    </div>
                                </div>
                            }
                        </>
                }
            ],
            action: (item.cardStatusXc === StatusInactive ||
                item.cardStatusXc === StatusInProduction ||
                item.cardStatusXc === StatusClosedByClient) && {
                text: "Cards.Activate",
                isDisabled: item.cardStatusXc === StatusInProduction
            },
            isReadOnly: (item.cardStatusXc !== StatusOk && item.cardStatusXc !== StatusClosedStoplist) || !item.isCardOwner,
            openedClassName: "field--colored no-padding"
        };
    }

    renderTableRowMobileJson(item) {
        const expiryDate = moment(item.expiryDate, "YYYY-MM-DD");

        return {
            content: [
                {
                    content:
                        <div>
                            <div>
                                {item.cardNumber}
                            </div>
                            <div>
                                {this.renderStatus(item.cardStatusXc)}
                            </div>
                            <div>
                                {item.cardName}
                            </div>
                            <div>
                                {`${this.props.intl.formatMessage({ id: "Cards.UnusedAmount"})}: ${item.unusedAmount > 0 ? this.props.intl.formatNumber(item.unusedAmount, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                    }) : 0} €`
                                }
                            </div>
                        </div>,
                    className: "white-space-normal word-wrap",
                }
            ],
            expanded: [
                {
                    content:
                        <>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.ContractNumber" />
                                </div>
                                <div className="bold">{item.contractNumber}</div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.ContractReferenceNumber"/>
                                </div>
                                <div className="bold">{item.contractReferenceNumber}</div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.BookedAmount" />
                                </div>
                                <div className="bold">
                                    <Currency value={item.bookedAmount} zeroWithoutFractions />
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.Fuels" />
                                </div>
                                <div className="bold">
                                    <FormattedMessage id={`Cards.${item.cardFuelTypeXc}`} />
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.Products" />
                                </div>
                                <div className="bold">
                                    <FormattedMessage id={`Cards.${item.cardProductsXc}`} />
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.MonthlyLimit" />
                                </div>
                                <div className="bold">
                                    <Currency value={item.limitMonthly} />
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.DailyLimit" />
                                </div>
                                <div className="bold">
                                    <Currency value={item.limitDaily} />
                                </div>
                            </div>
                            <div className="border-bottom expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.ExpiryDate" />
                                </div>
                                <div className="bold">
                                    {expiryDate.format("DD.MM.YYYY")}
                                </div>
                            </div>
                            {item.cardStatusXc === StatusOk &&
                                <div className="border-bottom expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.Pin" />
                                    </div>
                                    <div className="bold">
                                        <PaymentCardPin cardNumber={item.cardNumber} />
                                    </div>
                                </div>
                            }
                            {!item.isVirtual &&
                                <div className="border-bottom expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.DeliveryAddress" />
                                    </div>
                                    <div className="bold">
                                        {item.deliveryAddress || "-"}
                                    </div>
                                </div>
                            }
                            <div className="expanded-item-cell">
                                <div className="expanded-item-cell__title">
                                    <FormattedMessage id="Cards.Title.CardType" />
                                </div>
                                <div className="bold">
                                    <span className="pr-10"><FormattedMessage id={item.isVirtual ? "Cards.IsVirtual" : "Cards.IsPlastic"} /></span>
                                    {item.isPlasticCardApplicable &&
                                        <Button
                                            unelevated
                                            onClick={() => this.orderPlasticCard(item.cardNumber)}
                                            className="mdc-button mdc-button--tiny">
                                            <FormattedMessage id={"Cards.OrderPlasticCard"} />
                                        </Button>
                                    }
                                </div>
                            </div>
                            {item.cardHolderIdCode && 
                                <div className="border-bottom expanded-item-cell">
                                    <div className="expanded-item-cell__title">
                                        <FormattedMessage id="Cards.Title.CardHolder" />
                                    </div>
                                    <div className="bold">
                                        {`${item.cardHolderName} (${item.cardHolderIdCode})`}
                                    </div>
                                </div>
                            }
                            

                        </>,
                        colspan: 4
                }
            ],
            action: (item.cardStatusXc === StatusInactive ||
                item.cardStatusXc === StatusInProduction ||
                item.cardStatusXc === StatusClosedByClient) && {
                text: "Cards.Activate",
                isDisabled: item.cardStatusXc === StatusInProduction
            },
            isReadOnly: (item.cardStatusXc !== StatusOk && item.cardStatusXc !== StatusClosedStoplist) || !item.isCardOwner,
            openedClassName: "field--colored no-padding"
        };
    }

    render() {
        const isLoading = this.props.fetching || this.props.exportCardsFetching;
        const hasItems = this.props.cards.items && this.props.cards.items.length > 0;

        const rows = this.props.cards.items || [];
        const tableRowsJson = rows.map(item => this.renderTableRowJson(item));
        const tableRowsMobileJson = rows.map(item => this.renderTableRowMobileJson(item));
        const canCreateCards = this.props.contracts
            ? this.props.contracts.some(x => x.type === Fuel && x.status === Active)
            : false;

        return (
            <Grid className="mdc-layout-grid--base">
                <div ref={this.contentRef}></div>
                <GridCell span={12}>
                    {canCreateCards &&
                        <Button className="mdc-button--outlined mb-mobile-10" onClick={this.openNewCardDialog}>
                            <i className="icon-plus" />
                            <FormattedMessage id="Cards.OrderNewCard" />
                        </Button>
                    }
                    <FilterButton
                        className="right mb-mobile-10"
                        active={this.state.showFilter}
                        callback={this.onChangeByField.bind(this, 'showFilter')}
                    />
                    {hasItems &&
                        <Button className="mdc-button--outlined right mr-mobile-up-10 mb-mobile-10 hidden-mobile" onClick={this.exportToExcel}>
                            <i className="icon-download" />
                            <FormattedMessage id="Cards.Export" />
                        </Button>
                    }
                </GridCell>
                <GridCell span={12}>
                    <PaymentCardFilter
                        open={this.state.showFilter}
                        onSubmit={this.onFilterSubmit}
                        filter={this.state.filter}
                    />
                </GridCell>
                <GridCell span={12}>
                    {isLoading &&
                        <Loader type={Loader.TYPE_CENTER} />}

                    {!isLoading && !hasItems &&
                        <AlertMessage
                            className="mb-30"
                            type={AlertMessage.TYPE_NOTICE}
                            title={<FormattedMessage id="Cards.NotFound" />}
                        />}
                    {this.state.showSuccess &&
                        <AlertMessage
                            className="mb-30"
                            isSmall={true}
                            type={AlertMessage.TYPE_DONE}
                            title={<FormattedMessage id={this.state.successMessage} />}
                        />
                    }
                    {this.state.showError &&
                        <AlertMessage
                            className="mb-30"
                            isSmall={true}
                            type={AlertMessage.TYPE_ALERT}
                            title={<FormattedMessage id="Error.Technical" />}
                        />
                    }
                    {this.props.location?.state?.contractStatus === CompletedFormStatus.ACCEPTED &&
                        <GridCell span={12} className="mb-30">
                            <AlertMessage type={AlertMessage.TYPE_DONE}
                                title={<FormattedMessage id="ContractsPage.ContractFuel.Success" />}
                                description={
                                    <FormattedMessage
                                        id="ContractsPage.ContractFuel.SuccessDescription"
                                        values={{
                                            tangiApp: <a
                                                href={this.props.intl.formatMessage({ id: "ContractsPage.ContractFuel.SuccessLinkUrl" })}
                                                target="_blank" rel="noopener noreferrer"
                                            >
                                                <FormattedMessage id="ContractsPage.ContractFuel.SuccessLinkText" />
                                            </a>
                                        }}
                                    />
                                }
                            />
                        </GridCell>
                    }
                    {rows.length > 0 &&
                        <>
                            <TableList
                                innerRef={this.listRef}
                                headers={PaymentCards.TableHeaders}
                                itemContent={tableRowsJson}
                                mobileHeaders={PaymentCards.TableHeadersMobile}
                                mobileItemContent={tableRowsMobileJson}
                                expandable={true}
                                orderBy={this.state.orderBy}
                                orderDescending={this.state.orderDescending}
                                orderCallback={this.orderBy}
                                changeRow="Cards.Change"
                                changeRowCallback={(index) => { this.selectCallback([index]) }}
                                actionCallback={this.actionCallback}
                            />
                            <Pagination
                                total={this.props.cards.total || 0}
                                selectedPage={this.state.selectedPage}
                                onChange={this.onPageChange}
                            />
                        </>
                    }
                    <CardDialog
                        key={"cardDialog" + this.state.openDialog}
                        items={this.state.itemsToDialog}
                        isOpen={this.state.openDialog}
                        onClose={this.closeDialog}
                        onSuccess={this.onSuccess}
                    />
                    {this.state.isCompany ?
                        <NewCardDialog
                            key={"newCardDialog" + this.state.openNewCardDialog}
                            isOpen={this.state.openNewCardDialog}
                            onClose={this.closeNewCardDialog}
                            onSuccess={this.onSuccess}
                        /> :
                        <NewCardDialogPrivateCustomer
                            key={"newCardDialog" + this.state.openNewCardDialog}
                            isOpen={this.state.openNewCardDialog}
                            onClose={this.closeNewCardDialog}
                            onSuccess={this.onSuccess}
                        />
                    }
                    <OrderPlasticCardDialog
                        isOpen={this.state.isOrderPlasticCardDialogOpen}
                        onClose={this.onCloseOrderPlasticCardDialog}
                        cardNumber={this.state.selectedVirtualCardNumber}
                        onSuccess={this.onSuccess}
                    />
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        cards: state.cards.data,
        fetching: state.cards.fetching,
        fetched: state.cards.fetched,

        exportCards: state.allCards.data,
        exportCardsFetching: state.allCards.fetching,

        contracts: state.contracts.data,
        fetchingContracts: state.contracts.fetching,
        fetchedContracts: state.contracts.fetched,

        activateCardFetched: state.postActivateCard.fetched,
        activateCardError: state.postActivateCard.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchCards, fetchAllCards, fetchContracts, activateCard, orderCardReset }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(PaymentCards)
);

import axios from 'axios';
import { trackException } from '../appInsights';
import auth from '../auth/authenticate';
import language from '../lang/lang';

const baseUrl = process.env.REACT_APP_ITK_API_URL;

let createHeaders = function () {
  let headers = {
    // common: {
      'Accept-Language': language.getLang()
    // }
  };

  let jwtToken = auth.getTokenJwt();
  if (!jwtToken) {
    return headers;
  }

  headers.Authorization = jwtToken;
  return headers;
}

let axiosInstance = axios.create({
  baseURL: baseUrl,
  validateStatus: (status) => {
    return status < 300 || status === 303;
  }
});

axiosInstance.interceptors.request.use(
  config => {
    config.headers = { ...config.headers, ...createHeaders() };

    if (config.avoidAuthorizationHeader) {
        // Header can exist before even coming here, so make sure it is definitely gone.
        delete config.headers.Authorization;
    }

    return config;
  },
  error => {
    trackException(error);
    Promise.reject(error)
  }
);

// Add a response interceptor Allow data for 303 but still reject!
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status === 303) {
      return Promise.reject(response)
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export default axiosInstance;
import React from "react";
import BaseFormComponent from "./BaseFormComponent";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Checkbox from '@material/react-checkbox';
import classnames from 'classnames';
import { Button } from "@rmwc/button";
import { isNullOrEmpty } from "../../helpers/objectHelper";

import "../../styles/react-components/table.scss";

class TableList extends BaseFormComponent {
    static ScreenWidthSm = 768;

    constructor(props) {
        super(props);

        this.state = {
            selectedItems: [],
            openedItems: [],
            isEveryoneCheck: false,
            orderBy: props.orderBy,
            orderDescending: props.orderDescending,
        };
        this.clearCache = this.clearCache.bind(this);
        this.renderHeaders = this.renderHeaders.bind(this);
        this.renderHeaderCell = this.renderHeaderCell.bind(this);
        this.renderTableContents = this.renderTableContents.bind(this);
        this.renderTableRow = this.renderTableRow.bind(this);
        this.renderButtonWithAction = this.renderButtonWithAction.bind(this);
        this.renderButtonAsReadOnly = this.renderButtonAsReadOnly.bind(this);
    }

    clearCache() {
        this.setState({
            selectedItems: [],
            isEveryoneCheck: false,
            openedItems: []
        });
    }

    toggleEveryone() {
        if (this.state.isEveryoneCheck) {
            this.setState({
                selectedItems: [],
                isEveryoneCheck: false
            })
        } else {
            this.setState({
                selectedItems: this.props.itemContent.map((item, index) => {
                    return index;
                }),
                isEveryoneCheck: true
            })
        }
    }

    orderBy(what) {
        this.setState({
            orderBy: what,
            orderDescending: this.state.orderBy === what ? !this.state.orderDescending : false
        }, () => { this.props.orderCallback(this.state.orderBy, this.state.orderDescending) })
    }

    toggleOpenItem(index) {
        let items = [...this.state.openedItems];
        if (items.some(x => x === index)) {
            items = items.filter(x => x !== index);
        } else {
            items.push(index);
        }

        this.setState({
            openedItems: items
        });
    }

    toggleSelectedItem(index) {
        let items = [...this.state.selectedItems];
        if (items.some(x => x === index)) {
            items = items.filter(x => x !== index);
        } else {
            items.push(index);
        }

        this.setState({
            selectedItems: items,
            isEveryoneCheck: items.length === this.props.itemContent.length
        });
    }

    activateSelectedItems() {
        this.props.selectCallback(this.state.selectedItems);
    }

    changeItem(index) {
        this.props.changeRowCallback(index);
    }

    activateAction(index) {
        this.props?.actionCallback(index);
    }

    renderButtonWithAction(action, index, isMobile) {
        return (
            <Button
                disabled={action.isDisabled}
                className={classnames("mdc-button--unelevated", isMobile ? "mdc-button--thin" : "mdc-button--wide")}
                onClick={this.activateAction.bind(this, index)}>
                <FormattedMessage id={action.text} />
            </Button>
        );
    }

    renderButtonAsReadOnly(item, index, isMobile) {
        return (
            !!item?.isReadOnly ? null :
                <Button
                    className={classnames(!isMobile ? "mdc-button--outlined" : "mdc-button--thin", "mdc-button--wide")}
                    onClick={this.changeItem.bind(this, index)}>
                    <i className="icon-write" />
                    <FormattedMessage id={this.props.changeRow} />
                </Button>
        );
    }

    renderTableRow(item, index, isMobile) {
        const isChecked = this.state.selectedItems.some(x => x === index);
        const isOpened = this.state.openedItems.some(x => x === index);

        let trailingIcon = {
            icon: "angle-down",
            strategy: "className",
            basename: "icon",
            prefix: "icon-"
        };
        if (isOpened) {
            trailingIcon.className = "rotate";
        }

        return (
            <React.Fragment key={index}>
                <tr className={classnames({
                    'no-border': isOpened
                }, item.className)}>
                    {this.props.selectable &&
                        <td className="bold">
                            <Checkbox
                                name={"checkbox" + item.cardNumber}
                                checked={isChecked}
                                indeterminate={false}
                                onChange={this.toggleSelectedItem.bind(this, index)}
                            />
                        </td>
                    }
                    {item.content.map((item, contentIndex) => {
                        return (<td key={contentIndex} colSpan={item.colspan} className={item.className} style={item.style}>{item.content}</td>)
                    })}
                    {this.props.changeRow &&
                        <td>
                            {item.action
                                ? this.renderButtonWithAction(item.action, index, isMobile)
                                : this.renderButtonAsReadOnly(item, index, isMobile)
                            }
                        </td>
                    }
                    {this.props.expandable &&
                        <td onClick={this.toggleOpenItem.bind(this, index)} className="expandable-icon-cell">
                            <Button className="mdc-button--text"
                                trailingIcon={trailingIcon}
                            />
                        </td>
                    }
                </tr>
                {isOpened &&
                    <tr className={classnames("table-list__expanded-row", item.openedClassName)}>
                        {this.props.selectable &&
                            <td></td>
                        }
                        {item.expanded.map((item, expandIndex) => {
                            return (<td key={expandIndex} colSpan={item.colspan}>
                                {item.content}
                            </td>)
                        })}
                        {!isMobile && this.props.changeRow &&
                            <td></td>
                        }
                    </tr>
                }
            </React.Fragment>
        );
    }

    renderHeaders() {
        let headers = [];

        if (this.props.selectable) {
            headers.push(
                <th key={99}>
                    <Checkbox
                        name={"checkboxEveryone"}
                        checked={this.state.isEveryoneCheck}
                        onChange={this.toggleEveryone.bind(this)}
                    />
                </th>);
        }

        if (this.props.screenWidth <= TableList.ScreenWidthSm && !isNullOrEmpty(this.props.mobileHeaders)) {
            headers.push(this.props.mobileHeaders.map(this.renderHeaderCell))
        } else {
            headers.push(this.props.headers.map(this.renderHeaderCell));
        }

        return <tr className={this.state.orderDescending ? "descending" : ""}>
            {headers}
        </tr>;
    }

    renderHeaderCell(item, index) {
        return <th key={index}
            className={classnames({
                "active": this.state.orderBy === item.order,
                "orderable": !!this.state.orderBy
            }, item.className)}
            colSpan={item.colspan}
            onClick={item.order ? this.orderBy.bind(this, item.order) : undefined}>
            {typeof item.label === 'string'
                ? <FormattedMessage id={item.label} />
                : item.label}
        </th>
    }

    renderTableContents() {
        if (this.props.screenWidth <= TableList.ScreenWidthSm && !isNullOrEmpty(this.props.mobileItemContent)) {
            return this.props.mobileItemContent.map((item, index) => this.renderTableRow(item, index, true));
        } else {
            return this.props.itemContent.map((item, index) => this.renderTableRow(item, index, false));
        }
    }

    render() {
        return (
            <>
                <div className={classnames("table-wrap clearfix", this.props.className)}>
                    <table className="table-list">
                        <thead>
                            {this.renderHeaders()}
                        </thead>
                        <tbody>
                            {this.renderTableContents()}
                        </tbody>
                    </table>
                </div>
                {
                    this.props.selectable &&
                    <div>
                        <Button className="mdc-button--outlined" onClick={this.activateSelectedItems.bind(this)} disabled={this.state.selectedItems.length < 1}>
                            <i className="icon-write" />
                            <FormattedMessage id={this.props.selectableLabel} />
                        </Button>
                    </div>
                }
            </>
        );
    }
}

TableList.defaultProps = {
    itemContent: [],
    headers: [],
    mobileHeaders: [],
    mobileItemContent: [],
    selectable: false,
    selectableLabel: "Cards.Change",
    changeRow: null,
    expandable: false,
    orderBy: null,
    orderDescending: false,
    selectCallback: () => { },
    changeRowCallback: () => { },
    orderCallback: () => { },
    activateAction: () => { }
}

TableList.propTypes = {
    selectable: PropTypes.bool,
    selectableLabel: PropTypes.string,
    changeRow: PropTypes.string,
    expandable: PropTypes.bool,
    orderBy: PropTypes.string,
    orderDescending: PropTypes.bool,
    headers: PropTypes.array,
    itemContent: PropTypes.array,
    mobileHeaders: PropTypes.array,
    mobileItemContent: PropTypes.array,
    selectCallback: PropTypes.func,
    orderCallback: PropTypes.func,
    activateAction: PropTypes.func,
    className: PropTypes.any
};

function mapStateToProps(state) {
    return {
        screenWidth: state.ui.screenWidth
    };
}
function matchDispatchToProps(dispatch) {
    return {};
}


export default connect(mapStateToProps, matchDispatchToProps)(TableList);

import React, { useCallback, useEffect, useState } from "react";
import { groupBy, sortBy } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import BackButton from "../components/common/BackButton";
import EvCharger from "../components/Contract/EvCharger";
import {
    ContractDigitalSign,
    ContractTermsCheckbox,
    ContractUserData
} from "../components/Contract";
import Loader from "../components/common/Loader";
import { fetchAccount, fetchContacts } from "../actions/accountActions";
import {
    fetchEvChargingPdfPreview,
    fetchEvChargingOffers,
    prepareEvChargingContractForSigning,
    finalizeEvChargingContract
} from "../actions/evChargingActions";
import { ContractsPath, ServicePath } from "../const/routes";
import { useTimedRedirect, useValidator } from "../helpers/hooks";
import auth from "../auth/authenticate";

import "../styles/blocks/_ev-contract.scss";


const ContractEVCharging = () => {
    const evChargingOffers = useSelector(state => state.evChargingOffers);
    const account = useSelector(state => state.account);
    const contacts = useSelector(state => state.contacts);

    const [isLoading, setIsLoading] = useState(true);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [customerData, setCustomerData] = useState();
    const [currentOffer, setCurrentOffer] = useState();

    const [validator,] = useValidator();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { id } = useParams();
    const redirectToPage = useTimedRedirect();

    const fetchData = () => dispatch => {
        dispatch(fetchAccount());
        dispatch(fetchContacts());
        dispatch(fetchEvChargingOffers());
    }

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch]);

    const setOfferToForm = useCallback(() => {
        const offer = evChargingOffers.data.find(offer => offer.id === parseInt(id));

        if (!offer) {
            return;
        }

        setCurrentOffer(offer);
    }, [evChargingOffers, id]);

    useEffect(() => {
        if (evChargingOffers.fetched && evChargingOffers.data.length > 0) {
            setOfferToForm();
            setIsLoading(false);
        }
    }, [setOfferToForm, evChargingOffers.fetched, evChargingOffers.data]);

    const createAddressModel = () => {
        return {
            addressStreet: customerData.address?.street,
            addressHouseNo: customerData.address?.houseNr,
            addressApartmentNo: customerData.address?.apartmentNr,
            addressCityTown: customerData.address?.city,
            addressCounty: customerData.address?.county,
            addressParish: customerData.address?.parish,
            addressPostalCode: parseInt(customerData.address?.postalCode ?? 0)
        };
    }

    const createContractRequestModel = () => {
        const addressModel = createAddressModel();

        return {
            id: currentOffer.id,
            companyName: customerData.fullName,
            registryCode: customerData.code,
            emailDataCarrier: customerData.dataCarrier,
            email: customerData.email,
            phone: customerData.phone,
            ...addressModel
        }
    }

    const onPrepareSigning = () => async dispatch => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        const requestModel = createContractRequestModel();
        const { value } = await dispatch(prepareEvChargingContractForSigning(requestModel));

        return value.data;
    }

    const onContractSigned = () => async dispatch => {
        await dispatch(finalizeEvChargingContract(currentOffer.id));

        const state = { disableCache: true };
        redirectToPage(ContractsPath, state);
    }

    const onDownloadPreviewPdf = () => {
        const model = createContractRequestModel();
        dispatch(fetchEvChargingPdfPreview(model));
    }

    const renderValidityPeriod = () => {
        return (
            <Typography use="body2" className="text-bold">
                <span>
                    {currentOffer?.validityPeriod ?
                        intl.formatMessage({ id: "Contract.EVCharger.ValidityPeriod" }, {
                            date: moment().add(currentOffer.validityPeriod, "years").format("DD.MM.YYYY")
                        }) :
                        intl.formatMessage({ id: "Contract.EVCharger.NoExpiryDate" })
                    }
                </span>
                <span>&nbsp;</span>
                <span>
                    {currentOffer?.renewPeriod &&
                        intl.formatMessage({ id: "Contract.EVCharger.RenewDate" }, {
                            years: currentOffer.renewPeriod
                        })
                    }
                </span>
            </Typography>
        );
    }

    const renderSigningArea = () => {
        return (
            <ContractDigitalSign
                disabled={!isTermsAccepted}
                validator={validator}
                getFileToSign={onPrepareSigning}
                onDocumentSigned={onContractSigned}
                onDownloadPreview={onDownloadPreviewPdf}
            >
                <ContractTermsCheckbox
                    checked={isTermsAccepted}
                    onSetIsTermsAcceptedChanged={e => setIsTermsAccepted(e.target.checked)}
                    titleKey="Contract.EVCharging.AgreeTerms.Title"
                    termsValues={[
                        {
                            termsKey: "termsLink",
                            termsLinkKey: "Contract.EVCharging.AgreeTerms.TermsLink",
                            termsLinkTextKey: "Contract.EVCharging.AgreeTerms.TermsText",
                        },
                        {
                            termsKey: "priceListLink",
                            termsLinkKey: "Contract.EVCharging.AgreeTerms.PriceListLink",
                            termsLinkTextKey: "Contract.EVCharging.AgreeTerms.PriceListText",
                        }
                    ]}
                />
            </ContractDigitalSign>
        );
    }

    const renderContactArea = () => {
        const isCompany = auth.isCompany();

        return (
            <>
                {contacts.fetched && account.fetched &&
                    <>
                        <GridCell span={12} className="mdc-typography mdc-typography--headline4 mdc-typography--bold mb-25">
                            {isCompany
                                ? <FormattedMessage id="ContractsPage.AddContract.CompanyInfo" />
                                : <FormattedMessage id="ContractsPage.AddContract.ContactInfo" />
                            }
                        </GridCell>
                        <ContractUserData
                            contacts={contacts.data}
                            account={account.data}
                            setUserData={setCustomerData}
                            isReadOnly={false}
                            isCompany={isCompany}
                            hasDeliveryAddress={false}
                            validator={validator}
                        />
                    </>
                }
            </>
        );
    }

    const renderEvChargers = () => {
        const sortedProducts = sortBy(currentOffer?.products, ['evChargerAddress', 'productName']);
        const groupedByAddress = groupBy(sortedProducts, 'evChargerAddress');
        return (
            <div>
                {Object.entries(groupedByAddress).map(([ address, products ]) => (
                    <div key={ address }>
                        <Typography use="headline5" className="text-bold">{ address }</Typography>
                        <div className="ev-charger-products">
                            {products.map((product, i) => (
                                <EvCharger key={product.evChargerProductId + i} charger={product} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    const renderInstallationCost = () => {
        if (currentOffer?.installationCost)
            return (
                <span className="text-bold">
                    <FormattedMessage
                        id="Contract.EVCharger.ServiceSettings.InstallationCost"
                        values={{
                            sum: currentOffer?.installationCost
                        }}
                    />
                </span>
            );
    }

    const renderContractInfo = () => {
        return (
            <>
                <Typography
                    use="headline4"
                    tag="h4"
                    className="mdc-theme--primary mb-15 mt-25 text-bold"
                >
                    <FormattedMessage id="Contract.EVCharger.ServiceSettings.Title" />
                </Typography>
                <Typography use="body1">
                    <FormattedMessage id="Contract.EVCharger.ServiceSettings.Description" />
                </Typography>
                <Typography use="body1" className="text-bold">
                    { intl.formatMessage({ id: "Contract.EVCharger.ServiceRequired" }, {
                        isRequired: intl.formatMessage({ id: currentOffer?.serviceRequired ? 'General.Yes' : 'General.No'})})}
                    </Typography>
                {currentOffer?.thiAdded &&
                    <Typography use="body1" className="text-bold">
                        <FormattedMessage id="Contract.EVCharger.THIAdded" />
                    </Typography>
                }
                {currentOffer?.compensationMargin && currentOffer?.compensationMargin !== 0 &&
                    <Typography use="body1" className="text-bold">
                        {intl.formatMessage({ id: "Contract.EVCharger.CompensationMargin" }, {
                            sum: currentOffer.compensationMargin
                        })}
                    </Typography>
                }
                {renderInstallationCost()}
            </>
        );
    }

    const renderSelfBillingSection = () => {
        return (
            <>
                <Typography use="headline5" className="text-bold mb-15">
                    <FormattedMessage id="Contract.EVCharger.SelfBilling.Title" />
                </Typography>
                <Typography use="body1">
                    <span className="text-bold mr-10"><FormattedMessage id="Contract.EVCharger.SelfBilling.AccountNo" /></span>
                    <span>{currentOffer?.selfBillingInvoiceBankAccountNumber ?? "-"}</span>
                </Typography>
                <Typography use="body1">
                    <span className="text-bold mr-10"><FormattedMessage id="Contract.EVCharger.SelfBilling.ReferenceNo" /></span>
                    <span>{currentOffer?.selfBillingInvoiceReferenceNumber ?? "-"}</span>
                </Typography>
            </>
        );
    }

    if (isLoading) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    return (
        <Grid>
            <GridCell span={12}>
                <BackButton toPath={ServicePath} label="Contract.EVCharger.BackToServices" />
            </GridCell>
            <GridCell span={12}>
                {renderContactArea()}
            </GridCell>
            <GridCell span={12}>
                {renderContractInfo()}
            </GridCell>
            <GridCell span={12}>
                {renderSelfBillingSection()}
            </GridCell>
            <GridCell span={12}>
                {renderEvChargers()}
            </GridCell>
            <GridCell span={12}>
                {renderValidityPeriod()}
            </GridCell>
            <GridCell span={12}>
                {renderSigningArea()}
            </GridCell>
        </Grid>
    );
}

export default ContractEVCharging;